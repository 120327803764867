import React, { FC, useState } from 'react';
import { Button, Select, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { IOption } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { getBasketLegalAlertTitle } from 'entities/Basket/Basket.helper';

interface IComponentProps {
  subdivisionId?: number;
  legalOptions?: IOption[];
  legalId?: number;
  legalName?: string;
  readOnly?: boolean;
  canUpdateLegal: boolean;
  onChange: (legalId: number) => void;
}

export const BasketLegal: FC<IComponentProps> = (props) => {
  const { subdivisionId, legalOptions, legalId, legalName, readOnly, canUpdateLegal, onChange } = props;

  const [showField, setShowField] = useState<boolean>(false);

  const changeBtnDisabled = !subdivisionId || !legalOptions?.length || readOnly;
  const alertTitle = getBasketLegalAlertTitle(canUpdateLegal, legalId, subdivisionId, legalOptions, readOnly);

  const toggleShowField = () => setShowField((prev) => !prev);

  const onLegalChange = (value: number) => {
    if (value) {
      onChange(value);
    }

    toggleShowField();
  };

  return (
    <div className="basket-legal">
      <div>
        <span className="basket-legal__title">Юр.лицо</span>

        {alertTitle && (
          <Tooltip overlayClassName="basket-legal__tooltip" title={alertTitle}>
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>

      {!showField ? (
        <div className="basket-legal__value">
          {legalName && <span>{legalName}</span>}

          {canUpdateLegal && (
            <Button type="link" onClick={toggleShowField} disabled={changeBtnDisabled}>
              Изменить
            </Button>
          )}
        </div>
      ) : (
        <Select
          value={legalId}
          onChange={onLegalChange}
          className="basket-legal__select"
          options={legalOptions}
          suffixIcon={false}
          allowClear={{ clearIcon: <CloseIcon /> }}
        />
      )}
    </div>
  );
};
