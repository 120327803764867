import { ColumnsType } from 'antd/lib/table';
import { IBasketItem } from 'entities/Basket/Basket.models';

export const activeBasketsListDataToPayload = (data?: IBasketItem[]) => data?.map((item) => ({ key: item.id, ...item }));

export const renderActiveBasketsListRecords = () => {
  return [
    {
      dataIndex: 'id',
      render: (text) => `№ ${text}`,
    },
    {
      dataIndex: 'accountName',
    },
    {
      dataIndex: 'subdivisionName',
    },
    {
      dataIndex: 'user',
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      dataIndex: 'email',
    },
    {
      dataIndex: 'phone',
    },
  ] as ColumnsType<IBasketItem>;
};

export const getActiveBasketsListFilterOptions = (data?: IBasketItem[]) => {
  const basketsIdsOptions = data?.map((item) => ({ label: `${item.id}`, value: item.id }));
  const basketsUsersOptions = data?.map((item) => ({ label: `${item.firstName} ${item.lastName}`, value: item.id }));

  return { basketsIdsOptions, basketsUsersOptions };
};
