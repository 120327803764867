import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSidebarMenuItems } from 'common/helpers/sidebar.helper';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const SidebarComponent: React.FC<AllType> = ({ authState }) => {
  const { data, userCatalogId } = authState;

  const location = useLocation();

  const roles = data?.access.roles;
  const menuItems = getSidebarMenuItems(location, userCatalogId, roles) as MenuProps['items'];

  return (
    <div className="sidebar">
      <Menu className="sidebar__nav" items={menuItems} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});

export const Sidebar = connect(mapState)(SidebarComponent);
