import { ERoute } from 'common/const/enum';

export const buyersWorkplaceSegmentedOptions = [
  {
    label: 'Заявки',
    value: ERoute.RequestList,
  },
  {
    label: 'Спецификации',
    value: ERoute.SpecificationList,
  },
];
