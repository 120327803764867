import React, { useState } from 'react';
import { Button, DatePicker, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import { EDateFormat, EMessage } from 'common/const/enum';
import { disabledDate, toPayloadDate } from 'common/helpers/date.helper';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';

interface IComponentProps {
  deliveryDate: string | null;
  disabled?: boolean;
  onChange: (deliveryDate: string | null) => void;
}

export const BasketDeliveryDate: React.FC<IComponentProps> = ({ deliveryDate, disabled, onChange }) => {
  const [showDateField, setShowDateField] = useState<boolean>(false);

  const toggleShowDateField = () => setShowDateField((prev) => !prev);

  const onDateChange = (value: Dayjs | null) => {
    if (value) {
      onChange(toPayloadDate(value));
      toggleShowDateField();
    }
  };

  return (
    <div className="basket-delivery-date">
      <div>
        <span className="basket-delivery-date__title">Дата поставки</span>

        {!disabled && (
          <Tooltip
            overlayClassName="basket-delivery-date__tooltip"
            title={EMessage.YouCanIndicateDesiredDateForReceivingTheGoods}
          >
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>

      {!showDateField ? (
        <div className="basket-delivery-date__value">
          <span>{deliveryDate}</span>

          {deliveryDate ? (
            <Button type="link" onClick={() => onChange(null)} disabled={disabled}>
              Удалить
            </Button>
          ) : (
            <Button type="link" onClick={toggleShowDateField} disabled={disabled}>
              Добавить
            </Button>
          )}
        </div>
      ) : (
        <div className="basket-delivery-date__container">
          <DatePicker
            onChange={onDateChange}
            locale={locale}
            allowClear={false}
            suffixIcon={false}
            disabledDate={disabledDate}
            format={EDateFormat.FullDateSlashSeparator}
          />

          <Button className="btn btn-icon basket-delivery-date__close" icon={<CloseIcon />} onClick={toggleShowDateField} />
        </div>
      )}
    </div>
  );
};
