import React, { FC, useMemo, useState } from 'react';
import { Button, Form, Modal, Spin, FormInstance } from 'antd';
import { IFormValues } from 'common/models';
import { mapPropertiesToFormBuilderProperties, renderPropertiesFilterForm } from 'common/helpers/properties.helper';
import { IProperty } from 'entities/Property/Property.models';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  loading: boolean;
  propertyList: IProperty[];
  template?: IProperty;
  defaultActiveKeys?: number[];
  onCancel: () => void;
  onApply: (values: IFormValues) => void;
}

export const CategoryFilterModal: FC<IComponentProps> = (props) => {
  const { open, form, loading, propertyList, template, defaultActiveKeys, onCancel, onApply } = props;

  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);

  const propertiesForm = useMemo(() => {
    if (template) {
      const mappedProperties = mapPropertiesToFormBuilderProperties(propertyList);

      return renderPropertiesFilterForm(mappedProperties, defaultActiveKeys);
    }

    return null;
  }, [propertyList, template, defaultActiveKeys]);

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <Form className="category-filter-modal" form={form} onFinish={onApply} onValuesChange={() => setFormIsChanged(true)}>
          <div className="modal-gray__body">
            <div className="category-filter-modal__body">{propertiesForm}</div>
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onCancel}>
              Отмена
            </Button>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldsError }) => {
                const errors = getFieldsError();
                const hasErrors = errors.some((error) => error.errors.length);

                return (
                  <Button className="btn btn-primary" htmlType="submit" disabled={hasErrors || !formIsChanged}>
                    Применить
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
