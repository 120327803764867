import React from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ERoute, ESettingsMenuTab, EUserRole } from 'common/const/enum';
import { getSettingsPath } from 'common/helpers/router.helper';
import { ReactComponent as LogoIcon } from 'app/assets/images/logo.svg';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const HeaderComponent: React.FC<AllType> = ({ authState, deleteAuth, logoutAsUser }) => {
  const { data, superAdminAsUser } = authState;

  const isSuperAdmin = data?.access.roles.includes(EUserRole.SuperAdmin);

  const navigate = useNavigate();

  const handleLogoutAsUser = async () => {
    await logoutAsUser();
    navigate(ERoute.AccountList);
  };

  const items: MenuProps['items'] = [
    {
      key: 'logout',
      label: superAdminAsUser ? 'Вернуться в аккаунт суперадминистратора' : 'Выйти',
      onClick: superAdminAsUser ? handleLogoutAsUser : deleteAuth,
    },
  ];

  if (!isSuperAdmin) {
    items.unshift({
      key: 'profile',
      label: 'Профиль',
      onClick: () => navigate(ERoute.Profile),
    });
  }

  return (
    <div className="header">
      <Link to={isSuperAdmin ? getSettingsPath(ESettingsMenuTab.AccountList) : ERoute.Profile}>
        <div className="header__logo">
          <LogoIcon />
          Sprout
        </div>
      </Link>

      <Dropdown className="header__profile" menu={{ items }}>
        <ProfileIcon />
      </Dropdown>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  deleteAuth: dispatch.authState.deleteAuth,
  logoutAsUser: dispatch.authState.logoutAsUser,
});

export const Header = connect(mapState, mapDispatch)(HeaderComponent);
