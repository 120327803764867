import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import Segmented, { SegmentedValue } from 'antd/es/segmented';
import { ERoute } from 'common/const/enum';
import { sellerManagerSegmentedOptions } from 'entities/SellerManagerWorkplace/SellerManagerWorkplace.const';
import { ActiveBasketsList } from 'entities/SellerManagerWorkplace/components/ActiveBasketsList';
import { QrfpList } from 'entities/Qrfp/components/QrfpList';

const SellerManagerWorkplaceComponent: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  const onTabChange = (value: SegmentedValue) => {
    navigate(value as string);
  };

  return (
    <Layout className="seller-manager-workplace">
      <Segmented options={sellerManagerSegmentedOptions} value={pathname} onChange={onTabChange} />

      {pathname === ERoute.ActiveBasketsList ? <ActiveBasketsList /> : <QrfpList />}
    </Layout>
  );
};

export const SellerManagerWorkplace = SellerManagerWorkplaceComponent;
