import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { DEFAULT_DELIVERY_IN_DAYS } from 'common/config';
import { ReactComponent as TruckIcon } from 'app/assets/images/truck-gray.svg';
import { IGoodsRemain } from 'entities/Goods/Goods.models';

export const getFormattedDeliveryTime = (days?: number) => {
  if (!days) {
    return `${DEFAULT_DELIVERY_IN_DAYS} дн.`;
  }

  const daysInWeek = 7;
  const daysInMonth = 30;

  if (days >= daysInMonth) {
    const months = Math.floor(days / daysInMonth);
    return `${months} мес.`;
  } else if (days >= daysInWeek) {
    const weeks = Math.floor(days / daysInWeek);
    return `${weeks} нед.`;
  } else {
    return `${days} дн.`;
  }
};

export const renderDeliveryTimeTooltipRecords = () => {
  return [
    {
      dataIndex: 'stockName',
      render: (_, record) => (record.count ? record.stockName : 'Под заказ'),
    },
    {
      dataIndex: 'count',
      render: (text) => (text ? `${text} шт.` : ''),
    },
    {
      dataIndex: 'deliveryInDays',
      render: (text) => (
        <div className="goods-delivery-time__tooltip-item-days">
          <TruckIcon />

          <span>{getFormattedDeliveryTime(text)}</span>
        </div>
      ),
    },
  ] as ColumnsType<IGoodsRemain>;
};

export const getGoodsDeliveryTime = (count: number, remains: IGoodsRemain[] = []) => {
  if (!remains.length) {
    return { deliveryTime: 'Уточнить у поставщика' };
  }

  let accumulatedCount = 0;
  let minDeliveryInDays: number | undefined = undefined;

  for (const item of remains) {
    if (item.count !== undefined) {
      accumulatedCount += item.count;

      if (item.deliveryInDays !== undefined) {
        minDeliveryInDays = item.deliveryInDays;
      }

      if (accumulatedCount >= count) {
        return {
          totalCount: accumulatedCount,
          deliveryTime: getFormattedDeliveryTime(minDeliveryInDays),
        };
      }
    }
  }

  return { deliveryTime: getFormattedDeliveryTime() };
};

export const sortRemainsByDeliveryInDays = (remains: IGoodsRemain[] = []) => {
  return remains.sort((a, b) => {
    if (a.deliveryInDays === undefined) {
      return 1;
    }

    if (b.deliveryInDays === undefined) {
      return -1;
    }

    return (a.deliveryInDays as number) - (b.deliveryInDays as number);
  });
};
