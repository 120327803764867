import React from 'react';
import { Spin } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header } from 'common/components/Header';
import { ERoute, ESettingsMenuTab, EUserRole } from 'common/const/enum';
import { Sidebar } from 'common/components/Sidebar';
import { useJivo } from 'common/hooks/useJivo';
import { getSettingsPath } from 'common/helpers/router.helper';
import { RootState } from 'app/store';
import { Profile } from 'entities/Users/components/Profile';
import { Settings } from 'entities/Settings/components/Settings';
import { Workspace } from 'entities/Workspaces/components/Workspace';
import { WorkspacePosition } from 'entities/Workspaces/components/WorkspacePosition';
import { Shop } from 'entities/Shop/components/Shop';
import { Listing } from 'entities/Listing/components/Listing';
import { PreviousRequests } from 'entities/Requests/components/PreviousRequests';
import { BuyersWorkplace } from 'entities/BuyersWorkplace/components/BuyersWorkplace';
import { Specification } from 'entities/Specifications/components/Specification';
import { BasketLayout } from 'entities/Basket/components/BasketLayout';
import { SellerManagerWorkplace } from 'entities/SellerManagerWorkplace/components/SellerManagerWorkplace';
import { BasketWrapper } from 'entities/Basket/components/BasketWrapper';
import { PropertiesSettings } from 'entities/Property/components/PropertiesSettings';
import { CatalogList } from 'entities/Catalog/components/CatalogList';
import { Catalog } from 'entities/Catalog/components/Catalog';
import { QrfpPosition } from 'entities/Qrfp/components/QrfpPosition';

type AllType = ReturnType<typeof mapState>;

const PrivateLayoutComponent: React.FC<AllType> = ({ authState }) => {
  const { data, loading } = authState;

  const isSuperAdmin = data?.access.roles.includes(EUserRole.SuperAdmin);
  const defaultPathForSuperAdmin = getSettingsPath(ESettingsMenuTab.AccountList);

  useJivo(data);

  if (!data && !loading) {
    return <Navigate to={ERoute.Login} />;
  }

  if (!data && loading) {
    return <Spin spinning />;
  }

  return (
    <div className="private-layout">
      <Header />

      <div className="private-layout__container">
        <Sidebar />

        <div className="private-layout__body">
          <Routes>
            {/* Super Admin */}
            <Route path={ERoute.CatalogList} element={<CatalogList />} />
            <Route path={ERoute.Catalog} element={<Catalog />} />
            <Route path={ERoute.PropertiesSettings} element={<PropertiesSettings />} />

            {/* Admin */}
            <Route path={ERoute.Workspace} element={<Workspace />} />
            <Route path={ERoute.WorkspacePosition} element={<WorkspacePosition />} />

            {/* Manager */}
            <Route path={ERoute.RequestList} element={<BuyersWorkplace />} />
            <Route path={ERoute.SpecificationList} element={<BuyersWorkplace />} />
            <Route path={ERoute.Request} element={<BasketWrapper role={EUserRole.Manager} />} />
            <Route path={ERoute.Specification} element={<Specification />} />

            {/* Seller Manager */}
            <Route path={ERoute.ActiveBasketsList} element={<SellerManagerWorkplace />} />
            <Route path={ERoute.ClientsQrfpList} element={<SellerManagerWorkplace />} />
            <Route path={ERoute.ActiveBasket} element={<BasketWrapper role={EUserRole.SellerManager} />} />

            {/* Common */}
            <Route path={ERoute.Profile} element={<Profile />} />
            <Route path={ERoute.Settings} element={<Settings />}>
              <Route path=":id" element={<Settings />}>
                <Route path="delivery/:buyerId" element={<Settings />} />
              </Route>
            </Route>
            <Route path={ERoute.Shop} element={<Shop />} />
            <Route path={ERoute.Listing} element={<Listing />} />
            <Route path={ERoute.PreviousRequests} element={<PreviousRequests />} />
            <Route path={ERoute.Basket} element={<BasketLayout />} />
            <Route path={ERoute.QrfpList} element={<BasketLayout />} />
            <Route path={ERoute.QrfpPosition} element={<QrfpPosition />} />
            {isSuperAdmin ? (
              <Route path="*" element={<Navigate to={defaultPathForSuperAdmin} />} />
            ) : (
              <Route path="*" element={<Profile />} />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});

export const PrivateLayout = connect(mapState)(PrivateLayoutComponent);
