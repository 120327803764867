import React from 'react';
import { Button, Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { EMessage, EUserRole } from 'common/const/enum';
import { GoodsDeliveryTime } from 'common/components/GoodsDeliveryTime';
import { GoodsCounter } from 'common/components/GoodsCounter';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { IOption } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { IBasket, IBasketGoods } from 'entities/Basket/Basket.models';
import { IRequest, IRequestGoods } from 'entities/Requests/Requests.models';

export const basketGoodsToDataSource = (goodsList: (IBasketGoods | IRequestGoods)[]) =>
  goodsList.map((goods) => ({ key: goods.goodId, ...goods }));

export const renderBasketRecords = (
  onCellClick: (id: number) => void,
  changeGoodsCount: (id: number, count: number) => void,
  deleteGoods: (id: number) => void,
  readOnly?: boolean,
) => {
  const onCell = (record: IBasketGoods | IRequestGoods, rest?: React.HTMLAttributes<any> & React.TdHTMLAttributes<any>) => ({
    onClick: () => onCellClick(record.goodId),
    ...rest,
  });

  return [
    {
      dataIndex: 'image',
      render: (text) => {
        return <Image wrapperClassName="image image-w67" className={!text ? 'img-empty' : ''} src={text} preview={false} />;
      },
      onCell,
      width: '99px',
    },
    {
      dataIndex: 'name',
      render: (_, record) => {
        return (
          <div className="basket__list-item-name">
            <div className="basket__list-item-codes">
              {record.sellerCode && <div className="basket__list-item-sellerCode">{`Код: ${record.sellerCode}`}</div>}

              <div className="basket__list-item-vendorCode">
                <span>Артикул клиента: </span>

                {record.vendorCode ? <span>{record.vendorCode}</span> : <ExclamationCircleFilled />}
              </div>
            </div>

            <div className="basket__list-item-name">{record.name}</div>
          </div>
        );
      },
      onCell,
    },
    {
      dataIndex: 'price',
      render: (text) => `${text} р.`,
      onCell: (record) => onCell(record, { className: 'basket__list-cell-price' }),
    },
    {
      dataIndex: 'count',
      render: (_, record) => (
        <GoodsCounter
          count={record.count}
          readOnly={readOnly || !record.isAvailableForCustomer}
          onChange={(count) => changeGoodsCount(record.goodId, count)}
        />
      ),
      width: 152,
    },
    {
      dataIndex: 'total',
      render: (_, record) => priceFormatter(record.price * record.count),
      onCell: (record) => onCell(record, { className: 'basket__list-cell-total' }),
    },
    {
      dataIndex: 'deliveryInDays',
      render: (_, record) => {
        return <GoodsDeliveryTime goods={record} count={record.count} />;
      },
      onCell,
    },
    {
      dataIndex: 'delete',
      render: (_, record) => {
        return (
          <Button className="btn btn-icon" icon={<CloseIcon />} disabled={readOnly} onClick={() => deleteGoods(record.goodId)} />
        );
      },
      width: 54,
    },
  ] as ColumnsType<IBasketGoods | IRequestGoods>;
};

export const basketGoodsToPayloadGoods = (goodsList: (IBasketGoods | IRequestGoods)[]) =>
  goodsList.map((goods) => ({ goodId: goods.goodId, count: goods.count }));

export const getBasketOperations = (
  readonly: boolean,
  sellerManagerBasketId: number | null,
  xBasketId: number | null,
  userId?: number,
  roles?: string[],
  basketId?: number,
  basketUserId?: number,
) => {
  let canUpdateSummary = true;
  let canUpdateGoods = true;
  let setDefaultSelectedGoodsIds = false;
  let canLinkBasket = false;
  let canUnlinkBasket = false;
  let canUpdateDeliveryAddress = true;
  let canUpdateLegal = true;

  if (roles?.includes(EUserRole.Manager) && readonly) {
    canUpdateSummary = false;
    canUpdateGoods = false;
    setDefaultSelectedGoodsIds = true;
  }

  if (roles?.includes(EUserRole.SellerManager) && userId !== basketUserId) {
    canUpdateSummary = false;
  }

  if (
    roles?.includes(EUserRole.SellerManager) &&
    sellerManagerBasketId &&
    sellerManagerBasketId !== basketId &&
    xBasketId !== basketId
  ) {
    canLinkBasket = true;
  }

  if (roles?.includes(EUserRole.SellerManager) && xBasketId && xBasketId === basketId) {
    canUpdateSummary = true;
    canUnlinkBasket = true;
    canUpdateDeliveryAddress = false;
    canUpdateLegal = false;
  }

  return {
    canUpdateSummary,
    canUpdateGoods,
    setDefaultSelectedGoodsIds,
    canLinkBasket,
    canUnlinkBasket,
    canUpdateDeliveryAddress,
    canUpdateLegal,
  };
};

export const getBasketOrderInfo = (
  selectedGoodsIds: number[],
  goodsList: (IBasketGoods | IRequestGoods)[],
  basket: IBasket | IRequest | null,
) => {
  const selectedGoodsCount = goodsList
    .filter((goods) => selectedGoodsIds.includes(goods.goodId))
    .reduce((acc, curr) => acc + curr.count, 0);
  const selectedGoodsPrice = goodsList
    .filter((goods) => selectedGoodsIds.includes(goods.goodId))
    .reduce((acc, curr) => acc + curr.price * curr.count, 0);
  const vat = goodsList
    .filter((goods) => selectedGoodsIds.includes(goods.goodId))
    .reduce((acc, curr) => acc + (curr.priceWithTaxes - curr.price) * curr.count, 0);
  const deliveryPrice = Number(basket?.deliveryPrice);
  const totalPrice = selectedGoodsPrice + deliveryPrice;
  const totalPriceWithVat = totalPrice + vat;

  return {
    selectedGoodsCount: `${selectedGoodsCount} шт.`,
    selectedGoodsPrice: priceFormatter(selectedGoodsPrice),
    deliveryPrice: priceFormatter(deliveryPrice),
    totalPrice: priceFormatter(totalPrice),
    vat: priceFormatter(vat),
    totalPriceWithVat: priceFormatter(totalPriceWithVat),
  };
};

export const getGoodsWithIncreasedPriceLimitModalDescription = (goodsList: (IBasketGoods | IRequestGoods)[]) => {
  const goodsNames = goodsList.map((goods) => `“${goods.name}”`);

  return `Цена товара/ов ${goodsNames.join(
    ', ',
  )} превышает лимит, установленный для вашего аккаунта. Для оформления заказа требуется удалить его/их из корзины.`;
};

export const getBasketDeliveryAddressAlertTitle = (
  canUpdateDeliveryAddress: boolean,
  addressId?: number,
  subdivisionId?: number,
  addressOptions?: IOption[],
  readOnly?: boolean,
) => {
  if (addressId || readOnly || !canUpdateDeliveryAddress) {
    return null;
  }

  if (!subdivisionId) {
    return EMessage.AccountIsNotLinkedToSubdivision;
  }

  if (!addressOptions?.length) {
    return EMessage.SubdivisionDoesNotHaveDeliveryAddresses;
  }

  return EMessage.MustSelectDeliveryAddress;
};

export const getBasketLegalAlertTitle = (
  canUpdateLegal: boolean,
  legalId?: number,
  subdivisionId?: number,
  legalOptions?: IOption[],
  readOnly?: boolean,
) => {
  if (legalId || readOnly || !canUpdateLegal) {
    return null;
  }

  if (!subdivisionId) {
    return EMessage.AccountIsNotLinkedToSubdivision;
  }

  if (!legalOptions?.length) {
    return EMessage.SubdivisionDoesNotHaveLegalEntities;
  }

  return EMessage.MustSelectLegal;
};
