import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { ERoute } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { IBasket, IBasketGoods } from 'entities/Basket/Basket.models';
import { BasketDeliveryDate } from 'entities/Basket/components/BasketDeliveryDate';
import { BasketDeliveryAddress } from 'entities/Basket/components/BasketDeliveryAddress';
import { BasketLegal } from 'entities/Basket/components/BasketLegal';
import { IRequest, IRequestGoods } from 'entities/Requests/Requests.models';
import { getBasketOrderInfo } from 'entities/Basket/Basket.helper';
import { addressListToOptionsWithId } from 'entities/Addresses/Addresses.helper';

interface IComponentProps {
  basket: IBasket | IRequest | null;
  selectedGoodsIds: number[];
  goodsList: (IBasketGoods | IRequestGoods)[];
  readOnly?: boolean;
  canUpdateDeliveryAddress: boolean;
  canUpdateLegal: boolean;
  onSendRequestClick: (comment: string) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketSummaryComponent: React.FC<AllType> = (props) => {
  const {
    basket,
    selectedGoodsIds,
    goodsList,
    readOnly,
    canUpdateDeliveryAddress,
    canUpdateLegal,
    addressListState,
    legalListState,
    onSendRequestClick,
    updateBasket,
  } = props;

  const { data: addressList } = addressListState;
  const { data: legalList } = legalListState;

  const [deliveryAddressId, setDeliveryAddressId] = useState<number | undefined>();
  const [legalId, setLegalId] = useState<number | undefined>();
  const [deliveryDate, setDeliveryDate] = useState<string | null>(null);
  const [comment, setComment] = useState<string>('');
  const navigate = useNavigate();

  const addressOptions = addressListToOptionsWithId(addressList);
  const legalOptions = legalList?.map((legal) => ({ label: legal.name, value: legal.id }));
  const { selectedGoodsCount, selectedGoodsPrice, deliveryPrice, totalPrice, vat, totalPriceWithVat } = getBasketOrderInfo(
    selectedGoodsIds,
    goodsList,
    basket,
  );
  const sendRequestDisabled =
    readOnly ||
    !selectedGoodsIds.length ||
    !basket?.addressId ||
    !basket?.legalId ||
    goodsList.some((goods) => selectedGoodsIds.includes(goods.goodId) && !goods.isAvailableForCustomer);

  const onDeliveryAddressChange = (addressId: number) => {
    if (basket) {
      setDeliveryAddressId(addressId);
      updateBasket({ id: basket.id, addressId });
    }
  };

  const onLegalChange = (legalId: number) => {
    if (basket) {
      setLegalId(legalId);
      updateBasket({ id: basket.id, legalId });
    }
  };

  const onDeliveryDateChange = (deliveryDate: string | null) => {
    if (basket) {
      setDeliveryDate(deliveryDate);
      updateBasket({ id: basket.id, deliveryDate });
    }
  };

  const onPreviousOrdersClick = () => {
    navigate(ERoute.PreviousRequests);
  };

  useEffect(() => {
    if (basket) {
      const { addressId, legalId, deliveryDate } = basket;

      setDeliveryAddressId(addressId);
      setLegalId(legalId);
      setDeliveryDate(deliveryDate ? deliveryDate : null);
    }
  }, [basket]);

  return (
    <div className="basket-summary">
      <div className="basket-summary__body">
        <div className="basket-summary__limit">
          <span>Доступный лимит средств:</span>

          <span>{priceFormatter(basket?.availableLimit)}</span>
        </div>

        {basket?.maxGoodPrice && (
          <div className="basket-summary__limit">
            <span>Лимит цены на товар:</span>

            <span>{priceFormatter(basket?.maxGoodPrice)}</span>
          </div>
        )}

        <BasketDeliveryAddress
          subdivisionId={basket?.subdivisionId}
          addressOptions={addressOptions}
          addressId={deliveryAddressId}
          addressName={basket?.address}
          readOnly={readOnly}
          canUpdateDeliveryAddress={canUpdateDeliveryAddress}
          onChange={onDeliveryAddressChange}
        />

        <BasketLegal
          subdivisionId={basket?.subdivisionId}
          legalOptions={legalOptions}
          legalId={legalId}
          legalName={basket?.legalName}
          readOnly={readOnly}
          canUpdateLegal={canUpdateLegal}
          onChange={onLegalChange}
        />

        <BasketDeliveryDate deliveryDate={deliveryDate} disabled={readOnly} onChange={onDeliveryDateChange} />

        <div className="basket-summary__info">
          <span className="basket-summary__info-title">Информация о заказе</span>

          <div className="basket-summary__info-details">
            <div className="basket-summary__info-details-item">
              <span>Выбрано товаров:</span>

              <span className="basket-summary__info-details-item-value">{selectedGoodsCount}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Стоимость товаров:</span>

              <span className="basket-summary__info-details-item-value">{selectedGoodsPrice}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Стоимость доставки:</span>

              <span className="basket-summary__info-details-item-value">{deliveryPrice}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Общая стоимость:</span>

              <span className="basket-summary__info-details-item-value">{totalPrice}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Сумма НДС:</span>

              <span className="basket-summary__info-details-item-value">{vat}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Итого с НДС:</span>

              <span className="basket-summary__info-details-item-value">{totalPriceWithVat}</span>
            </div>
          </div>
        </div>

        <div className="basket-summary__comment">
          <span className="basket-summary__comment-title">Комментарий</span>

          <Input.TextArea
            rows={4}
            placeholder="Напишите тут..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={readOnly}
          />
        </div>
      </div>

      <div className="basket-summary__footer">
        <Button type="link" onClick={onPreviousOrdersClick} disabled={readOnly}>
          Предыдущие заказы
        </Button>

        <Button type="link" disabled={sendRequestDisabled} onClick={() => onSendRequestClick(comment)}>
          Отправить заявку
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  addressListState: state.addressListState,
  legalListState: state.legalListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateBasket: dispatch.basketState.updateBasket,
});

export const BasketSummary = connect(mapState, mapDispatch)(BasketSummaryComponent);
