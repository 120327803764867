import React, { FC, useState } from 'react';
import { Button, Tree } from 'antd';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { dataToTree } from 'common/helpers/tree.helper';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ICategoryDeletePayload, ICategory } from 'entities/Categories/Categories.models';
import { sortCategoryListByDisplayName } from 'entities/Categories/Categories.helper';

interface IComponentProps {
  catalogName?: string;
  categoryList: ICategory[];
  createSubcategory: (parentCategoryId?: number) => void;
  deleteSubcategory: (payload: ICategoryDeletePayload) => void;
  selectSubcategory: (id: number) => void;
}

export const CategoryTree: FC<IComponentProps> = (props) => {
  const { catalogName, categoryList, createSubcategory, deleteSubcategory, selectSubcategory } = props;

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const treeData = dataToTree<ICategory>(sortCategoryListByDisplayName(categoryList));

  const onSubcategorySelect = (keys: React.Key[]) => {
    if (keys.length) {
      setSelectedKeys(keys);
      selectSubcategory(Number(keys[0]));
    }
  };

  return (
    <div className="category-tree">
      <div className="category-tree__header">
        <div className="category-tree__catalog-name">{catalogName}</div>

        <ThreeDotMenu
          items={[
            {
              key: 'add',
              label: <Button onClick={() => createSubcategory()}>Добавить категорию</Button>,
              className: 'edit',
            },
          ]}
        />
      </div>

      <div className="category-tree__scroll-container">
        <Tree
          treeData={treeData}
          selectedKeys={selectedKeys}
          onSelect={onSubcategorySelect}
          switcherIcon={<ChevronIcon />}
          defaultExpandAll
          titleRender={(node) => {
            const category = categoryList?.find((category) => category.id === node.key) as ICategory;

            return (
              <>
                <span className={node.className}>{node.title as React.ReactNode}</span>

                <ThreeDotMenu
                  items={[
                    {
                      key: 'add',
                      label: (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            createSubcategory(category.id);
                          }}
                        >
                          Добавить подкатегорию
                        </Button>
                      ),
                      className: 'edit',
                    },
                    {
                      key: 'delete',
                      label: (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteSubcategory({ id: category.id });
                          }}
                        >
                          Удалить
                        </Button>
                      ),
                      className: 'delete',
                    },
                  ]}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
};
