import { ERoute } from 'common/const/enum';

export const sellerManagerSegmentedOptions = [
  {
    label: 'Корзины',
    value: ERoute.ActiveBasketsList,
  },
  {
    label: 'Запросы',
    value: ERoute.ClientsQrfpList,
    // TODO remove temp disabled
    disabled: true,
  },
];
