import React from 'react';
import { Modal, Image, Button, Spin } from 'antd';
import { GoodsCounter } from 'common/components/GoodsCounter';
import { GoodsDeliveryTime } from 'common/components/GoodsDeliveryTime';
import { IGoods } from 'entities/Goods/Goods.models';
import { GoodsCardVendorCode } from 'entities/Goods/components/GoodsCardVendorCode';

interface IComponentProps {
  open: boolean;
  goods: IGoods | null;
  count: number;
  loading: boolean;
  readOnly?: boolean;
  onChange?: (count: number) => void;
  onCancel: () => void;
  onAdd?: (goodId: number) => void;
  onDelete: (goodId: number) => void;
  onCategoryClick: (categoryId: number) => void;
}

export const GoodsCard: React.FC<IComponentProps> = (props) => {
  const { open, goods, count, loading, readOnly, onChange, onCancel, onAdd, onDelete, onCategoryClick } = props;

  if (!goods) {
    return null;
  }

  const { image, id, vendorCode, sellerCode, categoryId, categoryName, name, price, goodInBasket, properties, countryOfOrigin } =
    goods;

  return (
    <Modal wrapClassName="goods-card" open={open} width={1068} footer={false} onCancel={onCancel}>
      <Spin spinning={loading}>
        <div className="goods-card__body">
          <div className="goods-card__body-container">
            <Image
              wrapperClassName="image image-w156 goods-card__image"
              className={!image ? 'img-empty' : ''}
              src={image}
              preview={false}
            />

            <div className="goods-card__info">
              <div className="goods-card__details">
                <GoodsCardVendorCode goodsId={id} vendorCode={vendorCode} />

                {sellerCode && <div className="goods-card__seller-code">Код: {sellerCode}</div>}

                {countryOfOrigin && <div>Страна производства: {countryOfOrigin}</div>}

                <div className="goods-card__name">{name}</div>

                <div className="goods-card__details-footer">
                  <GoodsDeliveryTime goods={goods} count={count} />

                  <div className="goods-card__price">{`${price} р.`}</div>

                  {!readOnly && (
                    <div className="goods-card__actions">
                      {goodInBasket ? (
                        <Button className="btn btn-red" onClick={() => onDelete(id)}>
                          Удалить из корзины
                        </Button>
                      ) : (
                        <>
                          {onChange && <GoodsCounter count={count} onChange={onChange} />}

                          <Button className="btn btn-primary" onClick={() => onAdd?.(id)}>
                            Добавить в корзину
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="goods-card__characteristics">
                <div className="goods-card__characteristics-title">{`Технические характеристики ${name}`}</div>

                {properties.map((property, index) => {
                  if (categoryId && property.propertyName === 'Категория товара') {
                    return (
                      <div key={index} className="goods-card__characteristics-item">
                        <div className="goods-card__characteristics-item-name">{property.propertyName}</div>

                        <Button
                          className="goods-card__characteristics-item-value category btn-link"
                          type="link"
                          onClick={() => onCategoryClick(categoryId)}
                        >
                          {categoryName}
                        </Button>
                      </div>
                    );
                  }

                  return (
                    <div key={index} className="goods-card__characteristics-item">
                      <div className="goods-card__characteristics-item-name">{property.propertyName}</div>

                      <div className="goods-card__characteristics-item-value">{`${property.value} ${
                        property.unitOfMeasurement || ''
                      }`}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
