import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { ISearchPayload, ISearchResult, ISearchState } from 'entities/Search/Search.models';
import { searchTransport } from 'entities/Search/Search.transport';

export const searchState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ISearchState,
  reducers: {
    setSearch: (state, payload: ISearchResult) => ({ ...state, data: payload }),
    setSearchLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    resetSearch: (state) => ({ ...state, data: { categories: [], goods: [] } }),
  },
  effects: (dispatch) => ({
    async sendSearchRequest(payload: ISearchPayload) {
      dispatch.searchState.setSearchLoading(true);

      try {
        const response = await searchTransport.sendSearchRequest(payload);
        dispatch.searchState.setSearch(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.searchState.setSearchLoading(false);
      }
    },
  }),
});
