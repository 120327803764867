import React, { useEffect, useState } from 'react';
import { Layout, Select, Spin } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ERoute, ESearchParams } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ShopSearch } from 'common/components/ShopSearch';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { useCategoryClick } from 'common/hooks/useCategoryClick';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { ReactComponent as UpDownIcon } from 'app/assets/images/up-down.svg';
import { RootDispatch, RootState } from 'app/store';
import { ShopCategory } from 'entities/Shop/components/ShopCategory';
import { ShopNavigation } from 'entities/Shop/components/ShopNavigation';
import { BasketLink } from 'entities/Basket/components/BasketLink';
import { GoodsCard } from 'entities/Modal/components/GoodsCard';
import { getShopCatalogOptions } from 'entities/Shop/Shop.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ShopComponent: React.FC<AllType> = (props) => {
  const {
    catalogListState,
    categoryListState,
    categoryState,
    basketState,
    goodsState,
    goodsForNavigationState,
    catalogState,
    getCatalogList,
    getCategoryList,
    setCategoryList,
    getCategoryById,
    setCategory,
    getBasket,
    increaseBasketGoodsCount,
    decreaseBasketGoodsCount,
    getGoods,
    getGoodsForNavigation,
    updateBasket,
    getCatalogById,
    setCatalog,
  } = props;
  const { data: catalogList } = catalogListState;
  const { data: categoryList, loading: categoryListLoading } = categoryListState;
  const { data: category, loading: categoryLoading } = categoryState;
  const { data: basket, goodsCount } = basketState;
  const { loading: goodsForNavigationLoading } = goodsForNavigationState;
  const { data: goods, loading: goodsLoading } = goodsState;
  const { data: catalog, loading: catalogLoading } = catalogState;

  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);
  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [basketIsUpdating, setBasketIsUpdating] = useState<boolean>(false);
  const { searchParams, setSearchParam, removeSearchParam } = useSearchParamsHook();
  const { onCategoryClick } = useCategoryClick(getGoodsForNavigation, getCategoryList, getCategoryById);
  const navigate = useNavigate();

  const catalogId = searchParams.get(ESearchParams.CatalogId);
  const categoryId = searchParams.get(ESearchParams.CategoryId);
  const catalogOptions = getShopCatalogOptions(catalogList);
  const categoriesLoading = categoryLoading || categoryListLoading || goodsForNavigationLoading || goodsLoading;
  const goodsCardLoading = basketIsUpdating || goodsForNavigationLoading || categoryListLoading || categoryLoading;
  const selectedCatalogId = catalog?.id;

  const onCatalogChange = async (value: string) => {
    setCategory(null);
    removeSearchParam(ESearchParams.CategoryId);
    setSearchParam(ESearchParams.CatalogId, value);
    await getCatalogById(Number(value));
    await getCategoryList({ catalogId: Number(value), withoutParents: true, limit: 0 });
  };

  const onShopSearchGoodsClick = (id: number) => {
    getGoods({
      id,
      onSuccess: () => {
        setOpenGoodsCard(true);
      },
    });
  };

  const onShopSearchFindClick = (value: string) => {
    navigate(`${ERoute.Listing}?catalogId=${catalogId}&search=${value}&isAvailableForPurchase=true`);
  };

  const onShopNavigationItemClick = async (id: number) => {
    if (id === selectedCatalogId) {
      setCategory(null);
      removeSearchParam(ESearchParams.CategoryId);
      getCategoryList({ catalogId: id, withoutParents: true, limit: 0 });
    } else {
      await onCategoryClick(id);
    }
  };

  const onGoodsCardCancelClick = () => {
    setOpenGoodsCard(false);
    setCount(DEFAULT_GOODS_COUNT);
  };

  const onGoodsCardAddClick = (goodId: number) => {
    const good = { goodId, count };
    setBasketIsUpdating(true);

    if (basket) {
      updateBasket({
        id: basket.id,
        goods: [...basket.goods, good],
        onSuccess: () => {
          increaseBasketGoodsCount();
          onGoodsCardCancelClick();
          setBasketIsUpdating(false);
        },
      });
    }
  };

  const onGoodsCardDeleteClick = (goodId: number) => {
    if (basket) {
      setBasketIsUpdating(true);

      const filteredGoods = basket.goods.filter((good) => good.goodId !== goodId);

      updateBasket({
        id: basket.id,
        goods: filteredGoods,
        onSuccess: () => {
          decreaseBasketGoodsCount();
          onGoodsCardCancelClick();
          setBasketIsUpdating(false);
        },
      });
    }
  };

  const onGoodsCardCategoryClick = async (id: number) => {
    await onCategoryClick(id);
    setOpenGoodsCard(false);
  };

  useEffect(() => {
    return () => {
      setCatalog(null);
      setCategory(null);
      setCategoryList([]);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    await getBasket();
    const catalogList = await getCatalogList({ limit: 0 });

    if (catalogList) {
      const defaultCatalogId = catalogId ? Number(catalogId) : catalogList[0].id;

      if (!catalogId) {
        setSearchParam(ESearchParams.CatalogId, defaultCatalogId);
      }

      await getCatalogById(defaultCatalogId);
      await getCategoryList({
        catalogId: defaultCatalogId,
        withoutParents: !categoryId,
        parentId: categoryId ? Number(categoryId) : undefined,
        limit: 0,
      });
    }

    if (categoryId) {
      await getCategoryById(Number(categoryId));
    }
  });

  return (
    <Layout className="shop">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="shop__header">
            <div className="shop__filter">
              <div className="shop__filter-title">Отраслевой каталог</div>

              <Select
                className="shop__filter-select"
                placeholder="Выберите..."
                value={selectedCatalogId?.toString()}
                options={catalogOptions}
                suffixIcon={<UpDownIcon />}
                onChange={onCatalogChange}
              />
            </div>

            <ShopSearch
              catalogId={selectedCatalogId}
              onGoodsClick={onShopSearchGoodsClick}
              onCategoryClick={onCategoryClick}
              onFindClick={onShopSearchFindClick}
            />
          </div>

          <Spin wrapperClassName="shop__container" spinning={categoriesLoading || catalogLoading}>
            <div className="shop__nav-container">
              <ShopNavigation category={category} onClick={onShopNavigationItemClick} />

              <BasketLink count={goodsCount} />
            </div>

            <div className="shop__categories">
              {categoryList.map((category) => {
                return <ShopCategory key={category.id} category={category} changeCategory={onCategoryClick} />;
              })}
            </div>
          </Spin>

          <GoodsCard
            open={openGoodsCard}
            goods={goods}
            count={count}
            loading={goodsCardLoading}
            onChange={setCount}
            onCancel={onGoodsCardCancelClick}
            onAdd={onGoodsCardAddClick}
            onDelete={onGoodsCardDeleteClick}
            onCategoryClick={onGoodsCardCategoryClick}
          />
        </>
      )}
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  catalogListState: state.catalogListState,
  categoryListState: state.categoryListState,
  categoryState: state.categoryState,
  basketState: state.basketState,
  goodsState: state.goodsState,
  goodsForNavigationState: state.goodsForNavigationState,
  catalogState: state.catalogState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCatalogList: dispatch.catalogListState.getCatalogList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
  setCategoryList: dispatch.categoryListState.setCategoryList,
  getCategoryById: dispatch.categoryState.getCategoryById,
  setCategory: dispatch.categoryState.setCategory,
  getBasket: dispatch.basketState.getBasket,
  increaseBasketGoodsCount: dispatch.basketState.increaseBasketGoodsCount,
  decreaseBasketGoodsCount: dispatch.basketState.decreaseBasketGoodsCount,
  updateBasket: dispatch.basketState.updateBasket,
  getGoods: dispatch.goodsState.getGoods,
  getGoodsForNavigation: dispatch.goodsForNavigationState.getGoodsForNavigation,
  getCatalogById: dispatch.catalogState.getCatalogById,
  setCatalog: dispatch.catalogState.setCatalog,
});

export const Shop = connect(mapState, mapDispatch)(ShopComponent);
