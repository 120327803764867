import React from 'react';
import { Button, Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExclamationCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { EDateFormat, ESpecificationStatus, ESpecificationStatusLabel } from 'common/const/enum';
import { GoodsCounter } from 'common/components/GoodsCounter';
import { GoodsDeliveryTime } from 'common/components/GoodsDeliveryTime';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { ISpecificationGoods, ISpecificationItem } from 'entities/Specifications/Specifications.models';

export const renderSpecificationListRecords = () => {
  return [
    {
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).format(EDateFormat.FullDateSlashSeparator),
    },
    {
      dataIndex: 'id',
      render: (text) => `№ ${text}`,
    },
    {
      dataIndex: 'totalPrice',
      render: (text) => priceFormatter(Number(text)),
      width: '100%',
    },
    {
      dataIndex: 'status',
      render: (text: ESpecificationStatus) => (
        <div className={`specification-list__table-item-status ${text}`}>
          <span>{ESpecificationStatusLabel[text]}</span>
        </div>
      ),
    },
  ] as ColumnsType<ISpecificationItem>;
};

export const specificationGoodsToDataSource = (goodsList: ISpecificationGoods[]) =>
  goodsList.map((goods) => ({ key: goods.positionId, ...goods }));

export const renderSpecificationRecords = (
  onGoodsCountChange: (positionId: number, count: number) => void,
  onAddVendorCodeClick: (goodsId: number, initialValue?: string) => void,
  readOnly: boolean,
) => {
  return [
    {
      dataIndex: 'user',
      render: (_, record) => {
        return (
          <div className="specification__table-item-user">
            <div className="specification__table-item-user-name">{`${record.firstName} ${record.lastName}`}</div>

            <div className="specification__table-item-user-subdivision">{record.subdivisionName}</div>
          </div>
        );
      },
    },
    {
      dataIndex: 'image',
      render: (text) => {
        return <Image wrapperClassName="image image-w67" className={!text ? 'img-empty' : ''} src={text} preview={false} />;
      },
      width: '99px',
    },
    {
      dataIndex: 'name',
      render: (_, record) => {
        return (
          <div className="specification__table-item-name-container">
            <div className="specification__table-item-codes">
              {record.sellerCode && <div className="specification__table-item-sellerCode">{`Код: ${record.sellerCode}`}</div>}

              <div className="specification__table-item-vendorCode">
                <span>Артикул клиента: </span>

                {record.vendorCode ? (
                  <Button type="link" onClick={() => onAddVendorCodeClick(record.goodId, record.vendorCode)}>
                    {record.vendorCode}
                  </Button>
                ) : (
                  <div className="specification__table-item-vendorCode-empty">
                    <Button type="link" onClick={() => onAddVendorCodeClick(record.goodId)}>
                      добавить
                    </Button>

                    <ExclamationCircleFilled />
                  </div>
                )}
              </div>
            </div>

            <div className="specification__table-item-name">{record.name}</div>
          </div>
        );
      },
      width: '100%',
    },
    {
      dataIndex: 'price',
      render: (text) => <div className="specification__table-item-price">{priceFormatter(Number(text))}</div>,
    },
    {
      dataIndex: 'count',
      render: (_, record) => (
        <GoodsCounter
          count={record.count}
          onChange={(count) => onGoodsCountChange(record.positionId, count)}
          readOnly={readOnly}
        />
      ),
    },
    {
      dataIndex: 'total',
      render: (_, record) => <div className="specification__table-item-total">{priceFormatter(record.price * record.count)}</div>,
    },
    {
      dataIndex: 'deliveryInDays',
      render: (_, record) => {
        return <GoodsDeliveryTime goods={record} count={record.count} />;
      },
    },
    {
      dataIndex: 'address',
      render: (text) => <div className="specification__table-item-address">{text}</div>,
    },
  ] as ColumnsType<ISpecificationGoods>;
};

export const specificationGoodsToPayloadGoods = (goodsList: ISpecificationGoods[]) =>
  goodsList.map((goods) => ({ positionId: goods.positionId, count: goods.count, vendorCode: goods.vendorCode }));
