import { PHONE_PATTERN } from 'common/config';

export const goodsCountFormatter = (value?: number) => `${value} шт`;

export const phoneFormatter = (value?: string) => `${value?.replace(PHONE_PATTERN, '$1 $2 $3 $4 $5')}`;

export const priceFormatter = (price?: number) => `${price?.toFixed(2) || 0} р.`;

export const positionFormatter = (count: number) => {
  const countArray = Array.from(String(count)).map((num) => Number(num));
  const lastDigit = countArray[countArray.length - 1];
  const penultimateDigit = countArray[countArray.length - 2];

  switch (true) {
    case lastDigit === 1 && lastDigit === penultimateDigit:
    case lastDigit > 1 && lastDigit < 5 && penultimateDigit === 1:
    case lastDigit > 4 && lastDigit < 10:
    case lastDigit === 0: {
      /*
      1. Numbers including 11
      2. Numbers including 12, 13, 14
      3. Numbers ending in 5, 6, 7, 8, 9
      4. Numbers ending in 0
      */
      return `${count} позиций`;
    }
    case lastDigit > 1 && lastDigit < 5 && penultimateDigit !== 1: {
      // Numbers ending in 2, 3, 4, excluding numbers including 12, 13, 14
      return `${count} позиции`;
    }
    default: {
      // Numbers ending in 1, excluding numbers including 11
      return `${count} позиция`;
    }
  }
};

export const discountFormatter = (value?: number) => `${value}%`;
