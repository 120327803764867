import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';

export const toDataSourceMapper = <T>(data?: T[] | null): T[] | undefined =>
  data?.map((item, index) => ({ key: index, ...item }));

export const getId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const debounce = <A = unknown, R = void>(func: (args: A) => R) => {
  let timeoutId: NodeJS.Timeout;

  return (args: A) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(args);
    }, DEFAULT_DEBOUNCE_DELAY);
  };
};
