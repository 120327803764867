import React, { useState } from 'react';
import { Button, Select, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { IOption } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { getBasketDeliveryAddressAlertTitle } from 'entities/Basket/Basket.helper';

interface IComponentProps {
  subdivisionId?: number;
  addressOptions?: IOption[];
  addressId?: number;
  addressName?: string;
  readOnly?: boolean;
  canUpdateDeliveryAddress: boolean;
  onChange: (addressId: number) => void;
}

export const BasketDeliveryAddress: React.FC<IComponentProps> = (props) => {
  const { subdivisionId, addressOptions, addressId, addressName, readOnly, canUpdateDeliveryAddress, onChange } = props;

  const [showAddressField, setShowAddressField] = useState<boolean>(false);

  const changeBtnDisabled = !subdivisionId || !addressOptions?.length || readOnly;
  const alertTitle = getBasketDeliveryAddressAlertTitle(
    canUpdateDeliveryAddress,
    addressId,
    subdivisionId,
    addressOptions,
    readOnly,
  );

  const toggleShowAddressField = () => setShowAddressField((prev) => !prev);

  const onAddressChange = (value: number) => {
    if (value) {
      onChange(value);
    }

    toggleShowAddressField();
  };

  return (
    <div className="basket-delivery-address">
      <div>
        <span className="basket-delivery-address__title">Адрес доставки</span>

        {alertTitle && (
          <Tooltip overlayClassName="basket-delivery-address__tooltip" title={alertTitle}>
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>

      {!showAddressField ? (
        <div className="basket-delivery-address__value">
          <span>{addressName}</span>

          {canUpdateDeliveryAddress && (
            <Button type="link" onClick={toggleShowAddressField} disabled={changeBtnDisabled}>
              Изменить
            </Button>
          )}
        </div>
      ) : (
        <Select
          value={addressId}
          onChange={onAddressChange}
          className="basket-delivery-address__select"
          popupClassName="basket-delivery-address__select-dropdown"
          options={addressOptions}
          suffixIcon={false}
          allowClear={{ clearIcon: <CloseIcon /> }}
        />
      )}
    </div>
  );
};
