import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useCategoryClick } from 'common/hooks/useCategoryClick';
import { ERoute, ESearchParams } from 'common/const/enum';
import { searchParamsToInitialFormValues } from 'common/helpers/searchParams.helper';
import { ShopSearch } from 'common/components/ShopSearch';
import { ContentLoader } from 'common/components/ContentLoader';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ListingFilter } from 'entities/Listing/components/ListingFilter';
import { formValuesToGoodsListPayload } from 'entities/Listing/Listing.helper';
import { ShopNavigation } from 'entities/Shop/components/ShopNavigation';
import { BasketLink } from 'entities/Basket/components/BasketLink';
import { GoodsList } from 'entities/Goods/components/GoodsList';
import { IGoodsPosition } from 'entities/Goods/Goods.models';
import { ListingCategoryList } from 'entities/Listing/components/ListingCategoryList';
import { goodsListPositionsToPresetList } from 'entities/Goods/Goods.helper';
import { GoodsCard } from 'entities/Modal/components/GoodsCard';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ListingComponent: React.FC<AllType> = (props) => {
  const {
    categoryListState,
    propertyListState,
    goodsListState,
    goodsForNavigationState,
    basketState,
    categoryState,
    goodsState,
    catalogState,
    getCategoryList,
    setCategoryList,
    getPropertyListByCategoryId,
    setPropertyList,
    getGoodsList,
    updateGoodsInBasket,
    getGoodsForNavigation,
    getBasket,
    updateBasket,
    increaseBasketGoodsCount,
    decreaseBasketGoodsCount,
    getCategoryById,
    setCategory,
    getGoods,
    getCatalogById,
  } = props;
  const { data: categoryList, loading: categoryListLoading } = categoryListState;
  const { data: propertyList, loading: propertyListLoading } = propertyListState;
  const { data: goodsList, loading: goodsListLoading } = goodsListState;
  const { loading: goodsForNavigationLoading } = goodsForNavigationState;
  const { data: basket, goodsCount } = basketState;
  const { data: category, loading: categoryLoading } = categoryState;
  const { data: goods, loading: goodsLoading } = goodsState;
  const { data: catalog, loading: catalogLoading } = catalogState;

  const [positions, setPositions] = useState<IGoodsPosition[]>([]);
  const [defaultActiveKeys, setDefaultActiveKeys] = useState<number[]>([]);
  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);
  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [basketIsUpdating, setBasketIsUpdating] = useState<boolean>(false);
  const { searchParams } = useSearchParamsHook();
  const { onCategoryClick } = useCategoryClick(
    getGoodsForNavigation,
    getCategoryList,
    getCategoryById,
    category?.catalogId,
    initialLoadForCategory,
  );
  const navigate = useNavigate();
  const [form] = useForm();

  const categoryId = searchParams.get(ESearchParams.CategoryId);
  const catalogId = searchParams.get(ESearchParams.CatalogId);
  const search = searchParams.get(ESearchParams.Search);
  const presetList = goodsListPositionsToPresetList(positions, propertyList);
  const categoryInitialLoading =
    !!categoryId &&
    (goodsForNavigationLoading || categoryListLoading || categoryLoading || propertyListLoading || catalogLoading);
  const searchLoading = !!search && goodsListLoading;
  const goodsModalLoading = categoryInitialLoading || basketIsUpdating || goodsListLoading;

  async function initialLoadForCategory(id: number, searchParams: URLSearchParams) {
    setPositions([]);
    setDefaultActiveKeys([]);
    form.resetFields();

    await getCategoryList({ parentId: id, limit: 0 });
    const category = await getCategoryById(id);
    const propertyList = await getPropertyListByCategoryId(id);
    const { formValues, defaultActiveKeys } = searchParamsToInitialFormValues(searchParams, category, propertyList);
    const payload = formValuesToGoodsListPayload(formValues);

    setPositions(payload.positions);
    setDefaultActiveKeys(defaultActiveKeys);
    form.setFieldsValue(formValues);

    if (category) {
      await getCatalogById(category.catalogId);
    }

    await getGoodsList(payload);
  }

  const onShopSearchFindClick = async (value: string) => {
    const selectedCatalogId = catalog ? catalog.id : catalogId ? Number(catalogId) : undefined;

    if (selectedCatalogId) {
      setCategory(null);
      setCategoryList([]);
      setPropertyList([]);
      form.resetFields();
      navigate(`${ERoute.Listing}?catalogId=${selectedCatalogId}&search=${value}&isAvailableForPurchase=true`, { replace: true });
      await getGoodsList({ search: value, isAvailableForPurchase: true });
      form.setFieldsValue({ catalogId: selectedCatalogId, search: value, isAvailableForPurchase: true });
    }
  };

  const onShopNavigationItemClick = (id: number) => {
    if (category && id === category.catalogId) {
      navigate(`${ERoute.Shop}?catalogId=${id}`);
    } else {
      onCategoryClick(id);
    }
  };

  const renderHeader = (className: string) => {
    return (
      <div className={className}>
        <BasketLink count={goodsCount} />

        {categoryId && <ShopNavigation category={category} onClick={onShopNavigationItemClick} />}
      </div>
    );
  };

  const onGoodsListItemClick = (id: number) => {
    getGoods({
      id: Number(id),
      onSuccess: () => {
        setOpenGoodsCard(true);
      },
    });
  };

  const onGoodsCardCancelClick = () => {
    setOpenGoodsCard(false);
    setCount(DEFAULT_GOODS_COUNT);
  };

  const onGoodsCardAddClick = (id: number) => {
    if (basket) {
      const goods = { goodId: id, count };
      setBasketIsUpdating(true);

      updateBasket({
        id: basket.id,
        goods: [...basket.goods, goods],
        onSuccess: () => {
          onGoodsCardCancelClick();
          increaseBasketGoodsCount();
          updateGoodsInBasket({ id, inBasket: true });
          setBasketIsUpdating(false);
        },
      });
    }
  };

  const onGoodsCardDeleteClick = (id: number) => {
    if (basket) {
      setBasketIsUpdating(true);

      const filteredGoods = basket.goods.filter((goods) => goods.goodId !== id);

      updateBasket({
        id: basket.id,
        goods: filteredGoods,
        onSuccess: () => {
          onGoodsCardCancelClick();
          decreaseBasketGoodsCount();
          updateGoodsInBasket({ id, inBasket: false });
          setBasketIsUpdating(false);
        },
      });
    }
  };

  const onGoodsCardCategoryClick = async (id: number) => {
    await onCategoryClick(id);
    setOpenGoodsCard(false);
  };

  useEffect(() => {
    return () => {
      setCategory(null);
      setCategoryList([]);
      setPropertyList([]);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    await getBasket();

    if (categoryId) {
      await initialLoadForCategory(Number(categoryId), searchParams);
    }

    if (catalogId && search) {
      form.resetFields();
      await getCatalogById(Number(catalogId));
      await getGoodsList({ search, isAvailableForPurchase: true });
      form.setFieldsValue({ catalogId, search, isAvailableForPurchase: true });
    }
  });

  return (
    <Layout className="listing">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <ShopSearch
            catalogId={catalog?.id}
            onGoodsClick={onGoodsListItemClick}
            onCategoryClick={onCategoryClick}
            onFindClick={onShopSearchFindClick}
          />

          <Spin wrapperClassName="listing__container-spin" spinning={categoryInitialLoading || searchLoading}>
            <div className="listing__container">
              {renderHeader('listing__header')}

              <ListingFilter
                form={form}
                defaultActiveKeys={defaultActiveKeys}
                propertyList={propertyList}
                setPositions={setPositions}
                getGoodsList={getGoodsList}
              />

              <div className="listing__content">
                {renderHeader('listing__content_header')}

                <ListingCategoryList categoryList={categoryList} onCategoryClick={onCategoryClick} />

                <GoodsList
                  title={category ? category.displayName : search}
                  loading={goodsListLoading || goodsLoading}
                  category={category}
                  goodsList={goodsList}
                  presetList={presetList}
                  onGoodsClick={onGoodsListItemClick}
                />
              </div>
            </div>
          </Spin>
        </>
      )}

      <GoodsCard
        open={openGoodsCard}
        goods={goods}
        count={count}
        loading={goodsModalLoading}
        onChange={setCount}
        onCancel={onGoodsCardCancelClick}
        onAdd={onGoodsCardAddClick}
        onDelete={onGoodsCardDeleteClick}
        onCategoryClick={onGoodsCardCategoryClick}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  propertyListState: state.propertyListState,
  goodsListState: state.goodsListState,
  goodsForNavigationState: state.goodsForNavigationState,
  basketState: state.basketState,
  categoryState: state.categoryState,
  goodsState: state.goodsState,
  catalogState: state.catalogState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  setCategoryList: dispatch.categoryListState.setCategoryList,
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  setPropertyList: dispatch.propertyListState.setPropertyList,
  getGoodsList: dispatch.goodsListState.getGoodsList,
  updateGoodsInBasket: dispatch.goodsListState.updateGoodsInBasket,
  getGoodsForNavigation: dispatch.goodsForNavigationState.getGoodsForNavigation,
  getBasket: dispatch.basketState.getBasket,
  updateBasket: dispatch.basketState.updateBasket,
  increaseBasketGoodsCount: dispatch.basketState.increaseBasketGoodsCount,
  decreaseBasketGoodsCount: dispatch.basketState.decreaseBasketGoodsCount,
  getCategoryById: dispatch.categoryState.getCategoryById,
  setCategory: dispatch.categoryState.setCategory,
  getGoods: dispatch.goodsState.getGoods,
  getCatalogById: dispatch.catalogState.getCatalogById,
});

export const Listing = connect(mapState, mapDispatch)(ListingComponent);
