import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { EDateFormat } from 'common/const/enum';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { ICatalog } from 'entities/Catalog/Catalog.models';
import { IProperty } from 'entities/Property/Property.models';

export const renderCatalogListRecords = (
  handleUpdateCatalogClick: (record: ICatalog) => void,
  handleDeleteCatalogClick: (catalogId: number) => void,
) => {
  return [
    {
      title: 'Название каталога',
      dataIndex: 'name',
    },
    {
      title: 'Создан',
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).locale('ru').format(EDateFormat.FullDate),
      width: 200,
    },
    {
      title: 'Изменен',
      dataIndex: 'updatedAt',
      render: (text) => dayjs(text).locale('ru').format(EDateFormat.FullDate),
      width: 200,
    },
    {
      title: 'Автор',
    },
    {
      title: '',
      render: (_, record) => (
        <ThreeDotMenu
          items={[
            {
              key: 'edit',
              label: (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateCatalogClick(record);
                  }}
                >
                  Редактировать
                </Button>
              ),
              className: 'edit',
            },
            {
              key: 'delete',
              label: (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteCatalogClick(record.id);
                  }}
                >
                  Удалить
                </Button>
              ),
              className: 'delete',
            },
          ]}
        />
      ),
      width: 64,
    },
  ] as ColumnsType<ICatalog>;
};

export const findSelectedTemplatePropertiesForDisplay = (templateId: number, propertyList?: IProperty[]) => {
  if (!propertyList) {
    return [];
  }

  const property = propertyList.find((propertyItem) => propertyItem.id === templateId) as IProperty;

  return propertyList
    .filter((propertyItem) => {
      return (
        propertyItem.showCondition &&
        propertyItem.showCondition.some((condition) => {
          return condition.propertyId === property.parentId && condition.values.includes(property.displayName);
        })
      );
    })
    .map((propertyItem) => propertyItem.id);
};
