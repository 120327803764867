import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu, Spin, Table } from 'antd';
import type { MenuProps } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EMessage, EPositionFormMode, EQrfpStatus } from 'common/const/enum';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState } from 'app/store';
import { qrfpMenuItems } from 'entities/Qrfp/Qrfp.const';
import { renderQrfpListRecords } from 'entities/Qrfp/Qrfp.helper';
import { IQrfp } from 'entities/Qrfp/Qrfp.models';

interface IComponentProps {
  myQrfps?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const QrfpListComponent: FC<AllType> = ({ myQrfps, qrfpListState, categoryState, getQrfpList, getCategoryById, setCategory }) => {
  const { data: qrfpList, loading: qrfpListLoading } = qrfpListState;
  const { loading: categoryLoading } = categoryState;

  const [status, setStatus] = useState<EQrfpStatus>(EQrfpStatus.All);
  const navigate = useNavigate();

  const dataSource = toDataSourceMapper(qrfpList);
  const columns = renderQrfpListRecords();

  const onMenuItemClick: MenuProps['onClick'] = (item) => {
    setStatus(item.key as EQrfpStatus);
  };

  const onRowClick = async (qrfp: IQrfp) => {
    const response = await getCategoryById(qrfp.categoryId);

    if (response) {
      navigate(
        `/qrfp-position/${EPositionFormMode.View}?catalogId=${response.catalogId}&categoryId=${response.id}&positionId=${qrfp.id}`,
      );
    }
  };

  useEffect(() => {
    return () => {
      setCategory(null);
    };
  }, []);

  useEffect(() => {
    getQrfpList({ myQrfps, status: status === EQrfpStatus.All ? undefined : status, limit: 0 });
  }, [status]);

  return (
    <Layout className="qrfp-list">
      <Menu className="qrfp-list__menu" mode="vertical" selectedKeys={[status]} items={qrfpMenuItems} onClick={onMenuItemClick} />

      <div className="qrfp-list__content">
        <div className="qrfp-list__title">Запросы предложений</div>

        <Spin spinning={qrfpListLoading || categoryLoading}>
          <Table
            className="qrfp-list__table"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            showHeader={false}
            size="middle"
            onRow={(record) => ({ onClick: () => onRowClick(record) })}
            locale={{ emptyText: myQrfps ? EMessage.QRFPListIsEmpty : EMessage.ListIsEmpty }}
          />
        </Spin>
      </div>
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  qrfpListState: state.qrfpListState,
  categoryState: state.categoryState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getQrfpList: dispatch.qrfpListState.getQrfpList,
  getCategoryById: dispatch.categoryState.getCategoryById,
  setCategory: dispatch.categoryState.setCategory,
});

export const QrfpList = connect(mapState, mapDispatch)(QrfpListComponent);
