import { ERoute, EUserFrameRole, EUserRole, EUserRoleLabel } from 'common/const/enum';
import { IUser, IUserFrameItem, IUserListState } from 'entities/Users/Users.models';

export const getUsersFrameRoleOptions = (isAccountOwner?: boolean, isSeller?: boolean) => {
  return Object.values(EUserFrameRole).map((role) => ({
    label: EUserRoleLabel[role],
    value: role,
    disabled:
      role === EUserFrameRole.User ||
      (role === EUserFrameRole.AccountAdmin && isAccountOwner) ||
      (role === EUserFrameRole.SellerManager && !isSeller) ||
      (role === EUserFrameRole.Manager && isSeller),
  }));
};

export const getUserName = (data: IUser | null) => `${data?.firstName || ''} ${data?.lastName || ''}`;

export const getProfileFrames = (navigate: (path: string) => void, subdivisionId?: number, roles?: string[]) => {
  const frames: IUserFrameItem[] = [];

  if (!subdivisionId && !roles?.includes(EUserRole.SellerManager)) {
    return [];
  }

  frames.push(
    {
      title: 'Мои заказы',
      description: 'Информация о ранее размещенных заказах',
      onClick: () => navigate(ERoute.PreviousRequests),
    },
    {
      title: 'Активная корзина товаров',
      description: 'Нет незавершенных заказов',
      onClick: () => navigate(ERoute.Basket),
    },
  );

  if (roles?.includes(EUserRole.Manager)) {
    frames.push({
      title: 'Заявки от сотрудников',
      description: 'Обработка заявок от сотрудников',
      onClick: () => navigate(ERoute.RequestList),
    });
  }

  if (roles?.includes(EUserRole.SellerManager)) {
    frames.push({
      title: 'Заказы клиентов',
      description: 'Активные корзины клиентов и запросы на побор товаров',
      onClick: () => navigate(ERoute.ActiveBasketsList),
    });
  }

  return frames;
};

export const updateUserListState = {
  addUser: (state: IUserListState, payload: IUser) => ({ ...state, data: [...state.data, payload] }),
  updateUser: (state: IUserListState, payload: IUser) => ({
    ...state,
    data: state.data.map((user) => (user.id === payload.id ? payload : user)),
  }),
  deleteUser: (state: IUserListState, payload: number) => ({
    ...state,
    data: state.data.filter((user) => user.id !== payload),
  }),
};
