import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Layout, Spin, Table } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { EMessage, ENotificationType, ERoute, EStorageKey } from 'common/const/enum';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { useDebounce } from 'common/hooks/useDebounce';
import { useCategoryClick } from 'common/hooks/useCategoryClick';
import { removeStorageItem, saveStorageItem } from 'common/helpers/axios.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import {
  basketGoodsToDataSource,
  basketGoodsToPayloadGoods,
  getBasketOperations,
  getGoodsWithIncreasedPriceLimitModalDescription,
  renderBasketRecords,
} from 'entities/Basket/Basket.helper';
import { GoodsCard } from 'entities/Modal/components/GoodsCard';
import { IBasket, IBasketGoods } from 'entities/Basket/Basket.models';
import { Notification } from 'entities/Modal/components/Notification';
import { BasketSummary } from 'entities/Basket/components/BasketSummary';
import { QuicklyAddGoodsModal } from 'entities/Modal/components/QuicklyAddGoodsModal';
import { IGoods } from 'entities/Goods/Goods.models';
import { IRequest, IRequestGoods } from 'entities/Requests/Requests.models';
import { CreateGoodsFormModal } from 'entities/Modal/components/CreateGoodsFormModal';

interface IComponentProps {
  basket: IBasket | IRequest | null;
  loading: boolean;
  readOnly: boolean;
  sellerManagerBasketId: number | null;
  xBasketId: number | null;
  setXBasketId: (value: number | null) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketComponent: React.FC<AllType> = (props) => {
  const {
    basket,
    loading,
    readOnly,
    sellerManagerBasketId,
    xBasketId,
    categoryListState,
    authState,
    goodsState,
    setXBasketId,
    getCategoryList,
    updateBasket,
    createBasketRequest,
    uploadBasketExcel,
    getGoods,
    createGoods,
    getGoodsForNavigation,
    getCategoryById,
    setAuthUserCatalogId,
  } = props;

  const { data: categoryList, loading: categoryListLoading } = categoryListState;
  const { data: auth } = authState;
  const { data: goods, loading: goodsLoading } = goodsState;

  const catalogId = basket?.defaultCatalogId;

  const [goodsList, setGoodsList] = useState<(IBasketGoods | IRequestGoods)[]>([]);
  const [goodsWithIncreasedPriceLimit, setGoodsWithIncreasedPriceLimit] = useState<(IBasketGoods | IRequestGoods)[]>([]);
  const [goodsId, setGoodsId] = useState<number | null>(null);
  const [selectedGoodsIds, setSelectedGoodsIds] = useState<number[]>([]);
  const [goodsIsChanged, setGoodsIsChanged] = useState<boolean>(false);
  const [basketIsUpdating, setBasketIsUpdating] = useState<boolean>(false);
  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);
  const [openDeleteGoodsModal, setOpenDeleteGoodsModal] = useState<boolean>(false);
  const [openQuicklyAddGoodsModal, setOpenQuicklyAddGoodsModal] = useState<boolean>(false);
  const [openCreateGoodsModal, setOpenCreateGoodsModal] = useState<boolean>(false);
  const [openGoodsWithIncreasedPriceLimitModal, setOpenGoodsWithIncreasedPriceLimitModal] = useState<boolean>(false);
  const { onCategoryClick } = useCategoryClick(getGoodsForNavigation, getCategoryList, getCategoryById, catalogId);
  const navigate = useNavigate();

  const userId = auth?.access.userId;
  const roles = auth?.access.roles;
  const basketId = basket?.id;
  const basketUserId = basket?.userId;
  const basketOperations = getBasketOperations(readOnly, sellerManagerBasketId, xBasketId, userId, roles, basketId, basketUserId);
  const {
    canUpdateSummary,
    canUpdateGoods,
    setDefaultSelectedGoodsIds,
    canLinkBasket,
    canUnlinkBasket,
    canUpdateDeliveryAddress,
    canUpdateLegal,
  } = basketOperations;
  const checkAll = canUpdateGoods ? !!goodsList.length && goodsList.length === selectedGoodsIds.length : false;
  const dataSource = basketGoodsToDataSource(goodsList);
  const columns = renderBasketRecords(onGoodsCellClick, onGoodsCountChange, onGoodsDeleteClick, !canUpdateGoods);
  const goodsWithIncreasedPriceLimitModalDescription =
    getGoodsWithIncreasedPriceLimitModalDescription(goodsWithIncreasedPriceLimit);
  const basketLoading = loading || goodsLoading || categoryListLoading;

  function onGoodsCellClick(id: number) {
    setGoodsId(id);
    getGoods({ id, onSuccess: () => setOpenGoodsCard(true) });
  }

  const onGoodsCardCancelClick = () => {
    setGoodsId(null);
    setOpenGoodsCard(false);
  };

  function onGoodsDeleteClick(id: number) {
    if (basket) {
      setBasketIsUpdating(true);

      const filteredGoods = goodsList.filter((goods) => goods.goodId !== id);
      const filteredSelectedGoodsIds = selectedGoodsIds.filter((goodsId) => goodsId !== id);

      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(filteredGoods),
        onSuccess: () => {
          setSelectedGoodsIds(filteredSelectedGoodsIds);
          onGoodsCardCancelClick();
          setBasketIsUpdating(false);
        },
      });
    }
  }

  function onGoodsCountChange(id: number, count: number) {
    setGoodsIsChanged(true);

    const newGoodsList = goodsList.map((goods) => {
      if (goods.goodId === id) {
        return { ...goods, count };
      }

      return goods;
    });

    setGoodsList(newGoodsList);
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = goodsList.map((goods) => goods.goodId);
    setSelectedGoodsIds(e.target.checked ? checkedList : []);
  };

  const onDeleteGoodsModalConfirmClick = () => {
    if (basket && selectedGoodsIds.length) {
      setBasketIsUpdating(true);

      const filteredGoods = goodsList.filter((goods) => !selectedGoodsIds.includes(goods.goodId));

      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(filteredGoods),
        onSuccess: () => {
          setSelectedGoodsIds([]);
          setOpenDeleteGoodsModal(false);
          setBasketIsUpdating(false);
        },
      });
    }
  };

  const onGoodsWithIncreasedPriceLimitModalConfirmClick = async () => {
    const filteredGoodsList = goodsList.filter((goods) => {
      return !goodsWithIncreasedPriceLimit.some((item) => item.goodId === goods.goodId);
    });
    const filteredSelectedGoodsIds = selectedGoodsIds.filter((goodsId) => {
      return !goodsWithIncreasedPriceLimit.some((item) => item.goodId === goodsId);
    });

    if (basket) {
      setBasketIsUpdating(true);

      await updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(filteredGoodsList),
        onSuccess: () => {
          setSelectedGoodsIds(filteredSelectedGoodsIds);
          setGoodsWithIncreasedPriceLimit([]);
          setOpenGoodsWithIncreasedPriceLimitModal(false);
        },
      });

      setBasketIsUpdating(false);
    }
  };

  const onQuicklyAddGoodsModalAddToBasketClick = (goods: IGoods) => {
    if (basket) {
      setBasketIsUpdating(true);

      const newGoods = [...goodsList, { goodId: goods.id, count: DEFAULT_GOODS_COUNT } as IBasketGoods];

      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(newGoods),
        onSuccess: () => {
          setOpenQuicklyAddGoodsModal(false);
          setBasketIsUpdating(false);
        },
      });
    }
  };

  const onQuicklyAddGoodsModalAddGoodsToDatabaseClick = async () => {
    setOpenQuicklyAddGoodsModal(false);
    await getCategoryList({ catalogId, limit: 0 });
    setOpenCreateGoodsModal(true);
  };

  const sendRequest = () => {
    createBasketRequest({
      goods: basketGoodsToPayloadGoods(goodsList.filter((goods) => selectedGoodsIds.includes(goods.goodId))),
      onSuccess: () => {
        navigate(ERoute.PreviousRequests);
      },
    });
  };

  const onSendRequestClick = (comment: string) => {
    const goodsWithIncreasedPriceLimit = goodsList.filter((goods) => {
      return goods.price > Number(basket?.maxGoodPrice) && selectedGoodsIds.includes(goods.goodId);
    });

    if (goodsWithIncreasedPriceLimit.length) {
      setGoodsWithIncreasedPriceLimit(goodsWithIncreasedPriceLimit);
      setOpenGoodsWithIncreasedPriceLimitModal(true);
      return;
    }

    if (basket) {
      if (comment.trim().length) {
        updateBasket({
          id: basket.id,
          comment,
          onSuccess: sendRequest,
        });
      } else {
        sendRequest();
      }
    }
  };

  const onGoodsCardCategoryClick = async (id: number) => {
    await onCategoryClick(id);
    setOpenGoodsCard(false);
  };

  const linkBasket = async () => {
    if (basket) {
      saveStorageItem(EStorageKey.XBasketId, basket.id);
      saveStorageItem(EStorageKey.UserCatalogId, basket.defaultCatalogId as number);
      setAuthUserCatalogId(basket.defaultCatalogId as number);
      setXBasketId(basket.id);
    }
  };

  const unlinkBasket = () => {
    removeStorageItem(EStorageKey.XBasketId);
    removeStorageItem(EStorageKey.UserCatalogId);
    setAuthUserCatalogId(null);
    setXBasketId(null);
  };

  useEffect(() => {
    if (basket) {
      setGoodsList(basket.goods);
    }
  }, [basket]);

  useEffect(() => {
    if (basket && setDefaultSelectedGoodsIds) {
      const goodsIds = basket.goods.map((goods) => goods.goodId);
      setSelectedGoodsIds(goodsIds);
    }
  }, [basket, setDefaultSelectedGoodsIds]);

  useDebounce(() => {
    if (basket && goodsIsChanged) {
      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(goodsList),
        onSuccess: () => {
          setGoodsIsChanged(false);
        },
      });
    }
  }, [basket, goodsList, goodsIsChanged]);

  return (
    <Layout className="basket">
      <Spin wrapperClassName="basket__spin" spinning={basketLoading}>
        <div className="basket__container">
          {basket && (
            <BasketSummary
              basket={basket}
              selectedGoodsIds={selectedGoodsIds}
              goodsList={goodsList}
              readOnly={!canUpdateSummary}
              canUpdateDeliveryAddress={canUpdateDeliveryAddress}
              canUpdateLegal={canUpdateLegal}
              onSendRequestClick={onSendRequestClick}
            />
          )}

          <div className="basket__content">
            <div className="basket__header">
              <div>
                <span className="basket__title">Активная корзина</span>

                {basket && <span className="basket__id">{`№${basket.id}`}</span>}
              </div>

              {basket && (
                <Button className="btn btn-primary basket__export" onClick={() => uploadBasketExcel(basket.id)}>
                  Экспортировать в Excel
                </Button>
              )}
            </div>

            {!basket ? (
              <div>
                Корзина пуста. Добавьте товары из <Link to={ERoute.Shop}>Магазина</Link>
              </div>
            ) : (
              <>
                <div className="basket__btn-container">
                  <Button
                    className="btn btn-primary"
                    onClick={() => setOpenQuicklyAddGoodsModal(true)}
                    disabled={!canUpdateGoods}
                  >
                    Быстрое добавление товара
                  </Button>

                  {canLinkBasket && (
                    <Button className="btn btn-default" icon={<PlusOutlined />} onClick={linkBasket}>
                      Сделать текущей
                    </Button>
                  )}

                  {canUnlinkBasket && (
                    <Button className="btn btn-red" icon={<CloseOutlined />} onClick={unlinkBasket}>
                      Отвязать
                    </Button>
                  )}
                </div>

                <div className="basket__list-wrapper">
                  <div className="basket__list-actions">
                    <Checkbox checked={checkAll} onChange={onCheckAllChange} disabled={!canUpdateGoods}>
                      Выделить все / Снять выделение
                    </Checkbox>

                    <Button
                      className="basket__list-actions-remove-all"
                      icon={<CloseIcon />}
                      onClick={() => setOpenDeleteGoodsModal(true)}
                      disabled={!canUpdateGoods || !selectedGoodsIds.length}
                    >
                      Удалить выделенное
                    </Button>
                  </div>

                  <Table
                    rowSelection={{
                      selectedRowKeys: canUpdateGoods ? selectedGoodsIds : [],
                      onChange: (selectedRowKeys: React.Key[]) => {
                        setSelectedGoodsIds(selectedRowKeys as number[]);
                      },
                      getCheckboxProps: () => ({ disabled: !canUpdateGoods }),
                    }}
                    className="basket__list"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    showHeader={false}
                    locale={{ emptyText: 'Корзина пуста. Добавьте товары из Магазина' }}
                    rowClassName={(record) => (!record.isAvailableForCustomer ? 'not-available' : '')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Spin>

      <GoodsCard
        open={openGoodsCard}
        goods={goods}
        count={goodsList.find((item) => item.goodId === goodsId)?.count as number}
        loading={basketIsUpdating}
        readOnly={!canUpdateGoods}
        onCancel={onGoodsCardCancelClick}
        onDelete={onGoodsDeleteClick}
        onCategoryClick={onGoodsCardCategoryClick}
      />

      <Notification
        open={openDeleteGoodsModal}
        type={ENotificationType.Warning}
        description={EMessage.SelectedGoodsWillBeRemovedFromActiveBasket}
        loading={basketIsUpdating}
        onCancelClick={() => setOpenDeleteGoodsModal(false)}
        onConfirmClick={onDeleteGoodsModalConfirmClick}
      />

      <Notification
        open={openGoodsWithIncreasedPriceLimitModal}
        type={ENotificationType.Warning}
        description={goodsWithIncreasedPriceLimitModalDescription}
        loading={basketIsUpdating}
        confirmTitle="Удалить"
        onCancelClick={() => setOpenGoodsWithIncreasedPriceLimitModal(false)}
        onConfirmClick={onGoodsWithIncreasedPriceLimitModalConfirmClick}
      />

      <QuicklyAddGoodsModal
        open={openQuicklyAddGoodsModal}
        loading={basketIsUpdating}
        userRoles={roles}
        goodsInBasketIds={goodsList.map((goods) => goods.goodId)}
        onCancelClick={() => setOpenQuicklyAddGoodsModal(false)}
        onAddToBasketClick={onQuicklyAddGoodsModalAddToBasketClick}
        onAddGoodsToDatabaseClick={onQuicklyAddGoodsModalAddGoodsToDatabaseClick}
      />

      <CreateGoodsFormModal
        open={openCreateGoodsModal}
        loading={goodsLoading}
        categoryList={categoryList}
        onCancel={() => setOpenCreateGoodsModal(false)}
        onSubmit={createGoods}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  authState: state.authState,
  goodsState: state.goodsState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  updateBasket: dispatch.basketState.updateBasket,
  createBasketRequest: dispatch.basketState.createBasketRequest,
  uploadBasketExcel: dispatch.basketState.uploadBasketExcel,
  getGoods: dispatch.goodsState.getGoods,
  createGoods: dispatch.goodsState.createGoods,
  getGoodsForNavigation: dispatch.goodsForNavigationState.getGoodsForNavigation,
  getCategoryById: dispatch.categoryState.getCategoryById,
  setAuthUserCatalogId: dispatch.authState.setAuthUserCatalogId,
});

export const Basket = connect(mapState, mapDispatch)(BasketComponent);
