import React from 'react';
import type { MenuProps } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Link, Location } from 'react-router-dom';
import { ERoute, ESettingsMenuTab, EUserRole } from 'common/const/enum';
import { getSettingsPath, getShopPath } from 'common/helpers/router.helper';
import { ReactComponent as SettingsIcon } from 'app/assets/images/settings.svg';
import { ReactComponent as ListIcon } from 'app/assets/images/list.svg';
import { ReactComponent as ShopIcon } from 'app/assets/images/shop.svg';

export const getSidebarMenuItems = (location: Location, userCatalogId: number | null, roles?: string[]) => {
  const menuItems: MenuProps['items'] = [
    {
      key: 'settings',
      label: (
        <Link
          to={getSettingsPath(roles?.includes(EUserRole.SuperAdmin) ? ESettingsMenuTab.AccountList : ESettingsMenuTab.Account)}
        >
          <SettingsIcon />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SuperAdmin) && !roles?.includes(EUserRole.AccountAdmin),
    },
    {
      key: 'list',
      label: (
        <Link to={roles?.includes(EUserRole.SuperAdmin) ? ERoute.CatalogList : ERoute.ActiveBasketsList}>
          <ListIcon />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SuperAdmin) && !roles?.includes(EUserRole.SellerManager),
    },
    {
      key: 'collection',
      label: (
        <Link to={roles?.includes(EUserRole.SuperAdmin) ? ERoute.PropertiesSettings : ERoute.RequestList}>
          <UnorderedListOutlined />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SuperAdmin) && !roles?.includes(EUserRole.Manager),
    },
    {
      key: 'shop',
      label: (
        <Link to={getShopPath(location, userCatalogId)}>
          <ShopIcon />
        </Link>
      ),
      disabled: roles?.includes(EUserRole.SuperAdmin),
    },
  ];

  // @ts-ignore
  return menuItems.filter((item) => !item.disabled).map((item) => ({ ...item, className: 'sidebar__link' }));
};
