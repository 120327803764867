import React, { FC } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { EMessage, ESettingsMenuTab } from 'common/const/enum';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { getSettingsPath } from 'common/helpers/router.helper';
import { RootDispatch, RootState } from 'app/store';
import { renderContractListForSellerManagerRecords } from 'entities/Contract/Contract.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const ContractListForSellerManagerComponent: FC<AllType> = ({ contractListState, getContractList }) => {
  const { data: contractList } = contractListState;

  const navigate = useNavigate();

  const dataSource = toDataSourceMapper(contractList);
  const columns = renderContractListForSellerManagerRecords();

  const { contentLoading } = useContentLoader(async () => {
    await getContractList({ limit: 0 });
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="contract-list">
      <div className="contract-list__title">Контракты</div>

      <Table
        className="contract-list__table"
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        locale={{ emptyText: EMessage.ContractListForSellerManagerIsEmpty }}
        onRow={(record) => ({
          onClick: () => navigate(getSettingsPath(ESettingsMenuTab.ContractList, record.id)),
        })}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  contractListState: state.contractListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getContractList: dispatch.contractListState.getContractList,
});

export const ContractListForSellerManager = connect(mapState, mapDispatch)(ContractListForSellerManagerComponent);
