import React from 'react';
import { Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import LazyLoad from 'react-lazyload';
import { ICategory, ICategoryDetailed, ICategoryPropertyDto } from 'entities/Categories/Categories.models';
import { IGoods, IGoodsPosition } from 'entities/Goods/Goods.models';
import { IProperty } from 'entities/Property/Property.models';
import { IPresetItem } from 'entities/Presets/Preset.models';

export const findGroupingProperty = (goodsList: IGoods[], groupingPropertyId?: number) => {
  const allProperties = goodsList.map((goods) => goods.properties).flat();
  return allProperties.find((property) => property.propertyId === groupingPropertyId);
};

export const sortGoodsByGroupingProperty = (goodsList: IGoods[], groupingPropertyId?: number) => {
  const goodsWithGroupingProperty = goodsList.filter((goods) =>
    goods.properties.some((property) => property.propertyId === groupingPropertyId),
  );
  const goodsWithoutGroupingProperty = goodsList.filter((goods) =>
    goods.properties.every((property) => property.propertyId !== groupingPropertyId),
  );

  return { goodsWithGroupingProperty, goodsWithoutGroupingProperty };
};

const goodsToPropertiesToDisplay = (propertiesToDisplay: ICategoryPropertyDto[], goodsList: IGoods[]) => {
  return goodsList.map((goods) => {
    const properties = propertiesToDisplay.map((propertyToDisplay) => {
      const property = goods.properties.find((item) => item.propertyId === propertyToDisplay.propertyId);
      const propertyValue = `${property?.value || '-'} ${property?.unitOfMeasurement || ''}`;

      return [propertyToDisplay.propertyName, propertyValue];
    });

    return {
      ...Object.fromEntries(properties),
      key: goods.id,
      goodInBasket: goods.goodInBasket,
      ['бренд']: goods.brand,
      ['название']: goods.name,
      ['цена']: goods.price,
      image: goods.image,
    };
  });
};

export const goodsDataToDataSource = (goodsList: IGoods[], category: ICategoryDetailed | null) => {
  const propertiesToDisplay = category?.propertiesToDisplay?.sort((a, b) => a.propertyId - b.propertyId) || [];
  const { goodsWithGroupingProperty, goodsWithoutGroupingProperty } = sortGoodsByGroupingProperty(
    goodsList,
    category?.groupingProperty?.propertyId,
  );

  const goodsWithGroupingPropertyDataSource = goodsToPropertiesToDisplay(propertiesToDisplay, goodsWithGroupingProperty);
  const goodsWithoutGroupingPropertyDataSource = goodsToPropertiesToDisplay(propertiesToDisplay, goodsWithoutGroupingProperty);

  return { goodsWithGroupingPropertyDataSource, goodsWithoutGroupingPropertyDataSource };
};

export const renderGoodsRecords = (category: ICategory | null) => {
  const columns: ColumnsType<{ [key: string]: string | boolean }> = [
    {
      title: '',
      dataIndex: 'goodInBasket',
      key: 'goodInBasket',
      onCell: () => ({ className: 'goods-list__table-item-basket' }),
      render: (_, record) => record.goodInBasket && <div className="basket-icon" />,
      width: '15px',
    },
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      render: (text) => {
        return (
          <LazyLoad offset={100}>
            <Image
              wrapperClassName="image image-w67"
              className={!text ? 'img-empty' : ''}
              src={text}
              preview={false}
              loading="lazy"
            />
          </LazyLoad>
        );
      },
      width: '99px',
    },
    {
      title: 'бренд',
      dataIndex: 'бренд',
      key: 'brand',
      ellipsis: true,
    },
    {
      title: 'название',
      dataIndex: 'название',
      key: 'name',
      render: (text) => <div className="goods-list__table-item-name">{text}</div>,
      width: '30%',
    },
  ];

  category?.propertiesToDisplay?.forEach((property) => {
    columns.push({
      title: property.propertyName,
      dataIndex: property.propertyName,
      key: property.propertyId,
      ellipsis: true,
    });
  });

  columns.push({
    title: 'цена',
    dataIndex: 'цена',
    key: 'price',
    onCell: () => ({ className: 'goods-list__table-item-price' }),
  });

  return columns;
};

export const goodsListPositionsToPresetList = (positions: IGoodsPosition[], propertyList?: IProperty[]): IPresetItem[] => {
  if (!positions.length) {
    return [];
  }

  return positions.map((position) => {
    const property = propertyList?.find((propertyItem) => propertyItem.id === position.propertyId);

    if (property) {
      return {
        presetType: property.presetType,
        propertyId: position.propertyId,
        presetName: property.displayName,
        propertyType: property.type,
        unitOfMeasurement: property.unitOfMeasurement,
        values: position.values,
        range: position.range,
      };
    } else {
      return {} as IPresetItem;
    }
  });
};
