import React from 'react';
import { Button, type MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { ESearchParams, ESettingsMenuTab, EUserFilterOption, EUserFilterOptionLable, EUserRole } from 'common/const/enum';
import { EUserStatus } from 'common/const/enum';
import { getSettingsPath } from 'common/helpers/router.helper';
import { ReactComponent as SuccessIcon } from 'app/assets/images/success.svg';
import { ReactComponent as ErrorIcon } from 'app/assets/images/error.svg';
import { IUser } from 'entities/Users/Users.models';
import { IToken } from 'entities/Auth/Auth.models';

export const getSettingsNavItems = (roles?: string[], isSeller?: boolean) => {
  const navItems: MenuProps['items'] = [];

  if (roles?.includes(EUserRole.SuperAdmin)) {
    navItems.push(
      {
        key: ESettingsMenuTab.AccountList,
        label: <Link to={getSettingsPath(ESettingsMenuTab.AccountList)}>Список аккаунтов</Link>,
      },
      {
        key: ESettingsMenuTab.ContractList,
        label: <Link to={getSettingsPath(ESettingsMenuTab.ContractList)}>Контракты</Link>,
      },
    );
  }

  if (roles?.includes(EUserRole.AccountAdmin)) {
    navItems.push(
      {
        key: ESettingsMenuTab.Account,
        label: <Link to={getSettingsPath(ESettingsMenuTab.Account)}>Настройки аккаунта</Link>,
      },
      {
        key: ESettingsMenuTab.Users,
        label: <Link to={getSettingsPath(ESettingsMenuTab.Users)}>Пользователи</Link>,
      },
    );

    if (isSeller) {
      navItems.push(
        {
          key: ESettingsMenuTab.Stocks,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Stocks)}>Склады отгрузки</Link>,
        },
        {
          key: ESettingsMenuTab.Discounts,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Discounts)}>Скидки</Link>,
        },
        {
          key: ESettingsMenuTab.ContractList,
          label: <Link to={getSettingsPath(ESettingsMenuTab.ContractList)}>Контракты</Link>,
        },
        {
          key: ESettingsMenuTab.Integration,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Integration)}>Интеграции</Link>,
        },
      );
    } else {
      navItems.push(
        {
          key: ESettingsMenuTab.Subdivisions,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Subdivisions)}>Подразделения</Link>,
        },
        {
          key: ESettingsMenuTab.LegalEntities,
          label: <Link to={getSettingsPath(ESettingsMenuTab.LegalEntities)}>Юр.лица</Link>,
        },
      );
    }
  }

  return navItems.map((item) => ({ ...item, className: 'workplace-nav__link' }));
};

const usersListItemCellClassName = 'users__list_item-cell';

export const renderUsersSettingsRecords = (setSearchParams: (key: ESearchParams, value: string | number) => void) => {
  return [
    {
      onCell: () => ({ className: `${usersListItemCellClassName} status` }),
      key: 'status',
      render: (record) => (
        <div className="users__list_item-status">
          {record?.status === EUserStatus.Active ? <SuccessIcon className="success" /> : <ErrorIcon />}
        </div>
      ),
    },
    {
      onCell: () => ({ className: usersListItemCellClassName }),
      key: 'firstName',
      render: (record) => (
        <Button type="link" onClick={() => setSearchParams(ESearchParams.UserId, record.id)}>
          {`${record.firstName} ${record.lastName}`}
        </Button>
      ),
      ellipsis: true,
    },
    {
      onCell: () => ({ className: `${usersListItemCellClassName} role` }),
      key: 'role',
      render: (record) => (record.roles.includes(EUserRole.AccountAdmin) ? <span>Администратор</span> : null),
    },
    {
      onCell: () => ({ className: `${usersListItemCellClassName} email` }),
      key: 'email',
      render: (record) => <div title={record.email}>{record.email}</div>,
      ellipsis: true,
    },
  ] as ColumnsType<IUser>;
};

export const getUserSettingsFilterOptions = () => {
  return Object.values(EUserFilterOption).map((item) => ({ label: EUserFilterOptionLable[item], value: item }));
};

export const toUserSettingsSearchParams = (auth: IToken | null, searchValue: string, filterList: EUserFilterOption[]) => {
  return {
    accountId: auth?.access.accountId,
    search: searchValue,
    withoutSubdivision: filterList.includes(EUserFilterOption.WithoutSubdivision) ? true : undefined,
    status: filterList.includes(EUserFilterOption.NotActive) ? EUserStatus.NotActive : undefined,
    limit: 0,
  };
};
