import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { accountTransport } from 'entities/Account/Account.transport';
import {
  IAccountState,
  IAccount,
  IAccountCreatePayload,
  IAccountListState,
  IAccountListParams,
  IAccountUpdatePayload,
  IAccountBlockPayload,
  IAccountUnblockPayload,
  IAccountDeletePayload,
} from 'entities/Account/Account.models';
import { updateAccountListState } from 'entities/Account/Account.helper';

export const accountListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IAccountListState,
  reducers: {
    setAccountList: (state, payload: IAccount[]) => ({ ...state, data: payload }),
    setAccountListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addAccount: updateAccountListState.addAccount,
    updateAccount: updateAccountListState.updateAccount,
    deleteAccount: updateAccountListState.deleteAccount,
  },
  effects: (dispatch) => ({
    async getAccountList(params: IAccountListParams) {
      dispatch.accountListState.setAccountListLoading(true);

      try {
        const response = await accountTransport.getAccountList(params);
        dispatch.accountListState.setAccountList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountListState.setAccountListLoading(false);
      }
    },
  }),
});

export const accountState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IAccountState,
  reducers: {
    setAccount: (state, payload: IAccount) => ({ ...state, data: payload }),
    setAccountLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setAccountError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getAccountById(id: number) {
      dispatch.accountState.setAccountLoading(true);

      try {
        const response = await accountTransport.getAccountById(id);
        dispatch.accountState.setAccount(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountState.setAccountLoading(false);
      }
    },
    async createAccount(payload: IAccountCreatePayload) {
      dispatch.accountState.setAccountLoading(true);

      try {
        const response = await accountTransport.createAccount(payload);
        dispatch.accountListState.addAccount(response);
        dispatch.accountState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.accountState.setAccountError);
      } finally {
        dispatch.accountState.setAccountLoading(false);
      }
    },
    async updateAccount(payload: IAccountUpdatePayload) {
      dispatch.accountState.setAccountLoading(true);

      showLoadingMessage(0);

      try {
        const response = await accountTransport.updateAccount(payload);
        dispatch.accountListState.updateAccount(response);
        dispatch.accountState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.accountState.setAccountLoading(false);
      }
    },
    async blockAccount(payload: IAccountBlockPayload) {
      dispatch.accountState.setAccountLoading(true);

      try {
        const response = await accountTransport.blockAccount(payload);
        dispatch.accountListState.updateAccount(response);
        dispatch.accountState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountState.setAccountLoading(false);
      }
    },
    async unblockAccount(payload: IAccountUnblockPayload) {
      dispatch.accountState.setAccountLoading(true);

      try {
        const response = await accountTransport.unblockAccount(payload);
        dispatch.accountListState.updateAccount(response);
        dispatch.accountState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountState.setAccountLoading(false);
      }
    },
    async deleteAccount(payload: IAccountDeletePayload) {
      dispatch.accountState.setAccountLoading(true);

      try {
        const response = await accountTransport.deleteAccount(payload);
        dispatch.accountListState.deleteAccount(response.id);
        dispatch.accountState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountState.setAccountLoading(false);
      }
    },
  }),
});
