export enum ERoute {
  Activation = '/activation',
  Login = '/login',
  SendRestorePasswordEmail = '/send-restore-password-email',
  RestorePasswordConfirmation = '/restore-password-confirmation',
  RestorePassword = '/restore-password',
  AccountList = '/account-list',
  Profile = '/profile',
  Basket = '/basket',
  Settings = '/settings/:tab',
  Workspace = '/workspace/:id',
  WorkspacePosition = '/workspace-position/:mode',
  Shop = '/shop',
  Listing = '/listing',
  PreviousRequests = '/previous-requests',
  RequestList = '/request-list',
  Request = '/request-list/:id',
  SpecificationList = '/specification-list',
  Specification = '/specification/:id',
  ActiveBasketsList = '/active-baskets-list',
  ActiveBasket = '/active-baskets-list/:id',
  QrfpPosition = '/qrfp-position/:mode',
  QrfpList = '/qrfp-list',
  ClientsQrfpList = '/clients-qrfp-list',
  PropertiesSettings = '/properties-settings',
  Admin = '/admin/:tab',
  CatalogList = '/catalog-list',
  Catalog = '/catalog-list/:id',
}

export enum EUserRole {
  User = 'user',
  Manager = 'manager',
  SellerManager = 'seller_manager',
  AccountAdmin = 'account_admin',
  SuperAdmin = 'super_admin',
}

export enum EUserRoleLabel {
  user = 'Пользователь',
  manager = 'Закупщик',
  seller_manager = 'Аккаунт-менеджер',
  account_admin = 'Администратор аккаунта',
  super_admin = 'Администратор системы',
}

export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export enum EFormFieldMessage {
  RequiredField = 'Заполните поле',
  InvalidEmailFormat = 'Неверный формат электронной почты',
  InvalidPasswordFormat = 'Пароль должен иметь длину не менее 8 символов и содержать хотя бы одну заглавную букву, одну строчную букву и один специальный символ',
  InvalidPhoneFormat = 'Неверный формат телефона',
  PasswordMismatch = 'Пароли не совпадают',
  PasswordsMatch = 'Текущий и новый пароли совпадают',
  CharacterLimitMin = 'Не менее 3 символов',
  InvalidRange = 'Некорректный диапазон',
  LegalInnLength = 'ИНН должен быть равен 10 символам',
  LegalKppLength = 'КПП должен быть равен 9 символам',
  PropertyCascaderCharacterLimitMin = 'Не менее 1 символа',
}

export enum EAccountStatus {
  Active = 'active',
  Blocked = 'blocked',
}

export enum EAccountStatusTitle {
  active = 'Активный',
  blocked = 'Блокирован',
}

export enum ESettingsMenuTab {
  Account = 'account',
  Subdivisions = 'subdivisions',
  Users = 'users',
  LegalEntities = 'legal-entities',
  Discounts = 'discounts',
  Stocks = 'stocks',
  Integration = 'integration',
  AccountList = 'account-list',
  ContractList = 'contract-list',
}

export enum EAccountTariffPlanTitle {
  free = 'Бесплатный',
}

export enum EModalCollectionKey {
  CreateAccount = 'createAccountModalParams',
  BlockAccount = 'blockAccountModalParams',
  DeleteAccount = 'deleteAccountModalParams',
  ChangePassword = 'changePasswordModalParams',
  CreateSubdivision = 'createSubdivisionModalParams',
  DeleteSubdivision = 'deleteSubdivisionModalParams',
  ChangeSubdivisionLimit = 'changeSubdivisionLimitModalParams',
  SelectUser = 'selectUserModalParams',
  InviteUser = 'inviteUserModalParams',
  ChangeSubdivisionUser = 'changeSubdivisionUserModalParams',
  ExcludeSubdivisionUser = 'excludeSubdivisionUserModalParams',
  CreateSubdivisionAddress = 'createSubdivisionAddressModalParams',
  DeleteSubdivisionAddress = 'deleteSubdivisionAddressModalParams',
  CreateSubdivisionWorkspace = 'createSubdivisionWorkspaceModalParams',
  DeleteSubdivisionWorkspace = 'deleteSubdivisionWorkspaceModalParams',
  SelectSubdivision = 'selectSubdivisionModalParams',
  SelectWorkspace = 'selectWorkspaceModalParams',
  DeleteUser = 'deleteUserModalParams',
  SelectManager = 'selectManagerModalParams',
  SelectTemplate = 'selectTemplateModalParams',
  PositionUnsaved = 'positionUnsavedModalParams',
  DeleteWorkspacePosition = 'deleteWorkspacePositionModalParams',
  RepeatRequest = 'repeatRequestModalParams',
  ChangeSubdivisionCode = 'changeSubdivisionCodeModalParams',
  AddContract = 'addContractModalParams',
  LegalEntity = 'legalEntityModalParams',
  CreateDiscount = 'createDiscountModalParams',
  StockForm = 'stockFormModalParams',
  AddShippingStock = 'addShippingStockModalParams',
}

export enum ESearchParams {
  SubdivisionId = 'subdivisionId',
  UserId = 'userId',
  Frame = 'frame',
  WorkspaceId = 'workspaceId',
  PositionId = 'positionId',
  CategoryId = 'categoryId',
  BasketId = 'basketId',
  RequestId = 'requestId',
  QrfpId = 'qrfpId',
  IsAvailableForPurchase = 'isAvailableForPurchase',
  SellerId = 'sellerId',
  BuyerId = 'buyerId',
  ProductId = 'productId',
  Search = 'search',
  CatalogId = 'catalogId',
}

export enum ESubdivisionFrame {
  Users = 'users',
  Addresses = 'addresses',
  Workspaces = 'workspaces',
}

export enum EUserStatus {
  Active = 'active',
  NotActive = 'not_active',
}

export enum EUserStatusLabel {
  active = 'Активирован',
  not_active = 'Не активирован',
}

export enum EUserFilterOption {
  NotActive = 'not_active',
  WithoutSubdivision = 'without_subdivision',
}

export enum EUserFilterOptionLable {
  not_active = 'Не активированы',
  without_subdivision = 'Не привязаны к подразделению',
}

export enum EUserFrameRole {
  User = 'user',
  Manager = 'manager',
  AccountAdmin = 'account_admin',
  SellerManager = 'seller_manager',
}

export enum EPositionFormMode {
  Create = 'create',
  Edit = 'edit',
  Copy = 'copy',
  View = 'view',
}

export enum EPropertyType {
  Label = 'label',
  Number = 'number',
  List = 'list',
}

export enum EPresetTypeLabel {
  label = 'boolean',
  number = 'число',
  list = 'текст',
}

export enum EPresetType {
  NotApplicable = 'not_applicable',
  SingleValue = 'single_value',
  Range = 'range',
  MultipleValue = 'multiple_value',
  Checkbox = 'checkbox',
}

export enum EPresetTypeLabel {
  NotApplicable = 'Неприменимо',
  SingleValue = 'Единичное значение',
  Range = 'Диапазон',
  MultipleValue = 'Множественное значение',
  Checkbox = 'Флажок',
}

export enum ENotificationType {
  Warning = 'warning',
  Notification = 'notification',
}

export enum ECommonErrorMessage {
  UnexpectedError = 'Unexpected Error',
}

export enum EDateFormat {
  FullDateSlashSeparator = 'DD/MM/YYYY',
  FullDateDotSeparator = 'DD.MM.YYYY',
  FullDateReverse = 'YYYY-MM-DD',
  FullDate = 'DD MMMM YYYY',
}

export enum ERequestStatus {
  Active = 'active',
  Completed = 'completed',
}

export enum ESpecificationStatus {
  OnApproval = 'on_approval',
  Ordered = 'ordered',
}

export enum ESpecificationStatusLabel {
  on_approval = 'На согласовании',
  ordered = 'Заказ отправлен',
}

export enum EBasketStatus {
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum EPropertyFormType {
  AddPositionToWorkplaceForm = 'addPositionToWorkplaceForm',
  ListingFilterForm = 'listingFilterForm',
  AddGoodsForm = 'addGoodForm',
}

export enum EQrfpStatus {
  All = 'all',
  New = 'new',
  Completed = 'completed',
}

export enum EQrfpStatusLabel {
  all = '',
  new = 'Ожидает ответа',
  completed = 'Закрыт',
}

export enum EMessage {
  AccountIsNotLinkedToSubdivision = 'Ваш аккаунт не привязан ни к одному подразделению, обратитесь к администратору.',
  SubdivisionDoesNotHaveDeliveryAddresses = 'Подразделение не имеет адресов доставки, обратитесь к администратору.',
  MustSelectDeliveryAddress = 'Необходимо выбрать адрес доставки.',
  UnexpectedError = 'Произошла непредвиденная ошибка.',
  InvalidRefreshToken = 'Неверный рефреш токен.',
  NotAuthorized = 'Не авторизован',
  ClientsAccountWillBeBlocked = 'Аккаунт клиента будет заблокирован. При блокировке все пользователи, привязанные к Аккаунту клиента, потеряют доступ к данным в системе.',
  ClientsAccountWillBeDeleted = 'Аккаунт клиента будет удалён. Все данные будут потеряны. Вы уверены, что хотите удалить Аккаунт?',
  ManagerListIsEmpty = 'Список пуст. Создайте нового менеджера.',
  SelectedGoodsWillBeRemovedFromActiveBasket = 'Выбранные товары будут удалены из активной корзины.',
  YouCanIndicateDesiredDateForReceivingTheGoods = 'Вы можете указать желаемую дату получения товара. Система отметит товары, доставка которых невозможна в указанный срок.',
  NothingFound = 'Ничего не найдено.',
  WorkspaceListIsEmpty = 'Список пуст. Добавьте новую Карту рабочего места.',
  ThereAreAlreadyGoodsInActiveBasket = 'В Активной корзине уже есть товары. При подтверждении, текущие товары будут потеряны.',
  LetterWasSentSuccessfully = 'Письмо успешно отправлено',
  GoodsWillBeRemovedFromSpecification = 'Товар будет удален из спецификации.',
  SelectedGoodsWillBeRemovedFromSpecification = 'Выбранные товары будут удалены из спецификации.',
  SpecificationWillBeRemoved = 'При удалении последнего товара/ов спуцификация будет удалена.',
  InviteNewUser = 'Список пуст. Пригласите нового пользователя.',
  SubdivisionWillBeRemoved = 'Подразделение будет удалено. Все данные будут утеряны.',
  AddressListIsEmpty = 'Список пуст. Добавьте новый Адрес доставки.',
  AddressWillBeRemoved = 'Адрес будет удален из аккаунта.',
  AddRemoveUsersToSubdivision = 'Привязать/убрать пользователей в подразделение. Установить лимиты трат на покупки.',
  DeliveryAddressManagement = 'Управление адресами доставки, доступными для пользователей подразделения.',
  SetUpNeedForGoods = 'Настроить потребность в товарах на рабочих местах.',
  AddNewUser = 'Список пуст. Добавьте нового Пользователя.',
  UserWillBeRemovedFromSubdivision = 'Пользователь будет исключен из подразделения.',
  WorkspaceWillBeRemovedFromAccount = 'Карта рабочего места будет удалена из Аккаунта.',
  UserWillBeRemovedFromAccount = 'Пользователь будет удален из аккаунта.',
  ChangesWereNotSaved = 'Изменения не были сохранены.',
  ImageUploadError = 'Произошла ошибка при загрузке изображения.',
  ListIsEmpty = 'Список пуст.',
  QRFPListIsEmpty = 'Список пуст. Создайте новый запрос.',
  ContractListForSuperAdminIsEmpty = 'Список пуст. Создайте новый контракт.',
  LegalEntityListIsEmpty = 'Список пуст. Создайте новое юридическое лицо.',
  DiscountListIsEmpty = 'Список пуст. Создайте новый вид скидок.',
  DiscountsOnBrandsListIsEmpty = 'Список пуст. Создайте новую скидку для бренда.',
  StockListIsEmpty = 'Список пуст. Создайте новый склад отгрузки.',
  ContractListForSellerManagerIsEmpty = 'Список контрактов пуст.',
  CatalogListIsEmpty = 'Список пуст. Создайте новый каталог.',
  SubdivisionDoesNotHaveLegalEntities = 'Подразделение не имеет юридических лиц, обратитесь к администратору.',
  MustSelectLegal = 'Необходимо выбрать юридическое лицо.',
}

export enum EAddressModalTabKey {
  AutomaticSearch = 'automaticSearch',
  ManualInput = 'manualInput',
}

export enum EShopSearchType {
  Goods = 'goods',
  Category = 'category',
}

export enum EPropertyCascaderItemType {
  Properties = 'properties',
  Options = 'options',
  All = 'all',
}

export enum EStorageKey {
  Creds = 'creds',
  SuperAdminCreds = 'super-admin-creds',
  SellerManagerBasketId = 'seller-manager-basket-id',
  XUserId = 'x-user-id',
  XBasketId = 'x-basket-id',
  UserCatalogId = 'user-сatalog-id',
}
