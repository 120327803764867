import type { MenuProps } from 'antd';
import { EQrfpStatus } from 'common/const/enum';

export const qrfpMenuItems: MenuProps['items'] = [
  {
    key: 'all',
    label: 'Все запросы',
  },
  {
    key: EQrfpStatus.New,
    label: 'Ожидают ответа',
  },
  {
    key: EQrfpStatus.Completed,
    label: 'Закрыты',
  },
];
