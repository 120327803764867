import React, { FC, useState } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { getStorageItem } from 'common/helpers/axios.helper';
import { EStorageKey, EUserRole } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { Basket } from 'entities/Basket/components/Basket';

interface IComponentProps {
  role: EUserRole;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketWrapperComponent: FC<AllType> = (props) => {
  const { role, basketState, requestState, getBasket, getBasketById, getRequest, getAddressList, getLegalList } = props;

  const { data: basket, loading: basketLoading } = basketState;
  const { data: request, loading: requestLoading } = requestState;

  const [sellerManagerBasketId, setSellerManagerBasketId] = useState<number | null>(null);
  const [xBasketId, setXBasketId] = useState<number | null>(null);
  const { id } = useParams();

  const isUser = role === EUserRole.User;
  const isManager = role === EUserRole.Manager;
  const isSellerManager = role === EUserRole.SellerManager;

  const getAddresses = async (subdivisionId?: number) => {
    if (subdivisionId) {
      await getAddressList({ subdivisionId, limit: 0 });
    }
  };

  const { contentLoading } = useContentLoader(async () => {
    if (isUser) {
      const basket = await getBasket();
      await getAddresses(basket?.subdivisionId);
    }

    if (isManager && id) {
      const request = await getRequest(Number(id));
      await getAddresses(request?.subdivisionId);
    }

    if (isSellerManager && id) {
      const basket = await getBasketById(Number(id));
      await getAddresses(basket?.subdivisionId);
    }

    await getLegalList({ limit: 0 });
    const sellerManagerBasketId = getStorageItem<string>(EStorageKey.SellerManagerBasketId);
    const xBasketId = getStorageItem<string>(EStorageKey.XBasketId);

    if (sellerManagerBasketId) {
      setSellerManagerBasketId(Number(sellerManagerBasketId));
    }

    if (xBasketId) {
      setXBasketId(Number(xBasketId));
    }
  });

  if (contentLoading) {
    return (
      <div className="spin-container">
        <Spin />
      </div>
    );
  }

  return (
    <Basket
      basket={isManager ? request : basket}
      loading={basketLoading || requestLoading}
      readOnly={isManager}
      sellerManagerBasketId={sellerManagerBasketId}
      xBasketId={xBasketId}
      setXBasketId={setXBasketId}
    />
  );
};

const mapState = (state: RootState) => ({
  basketState: state.basketState,
  requestState: state.requestState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasket: dispatch.basketState.getBasket,
  getBasketById: dispatch.basketState.getBasketById,
  getRequest: dispatch.requestState.getRequest,
  getAddressList: dispatch.addressListState.getAddressList,
  getLegalList: dispatch.legalListState.getLegalList,
});

export const BasketWrapper = connect(mapState, mapDispatch)(BasketWrapperComponent);
