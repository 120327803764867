import React, { FC } from 'react';
import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERoute, EUserRole } from 'common/const/enum';
import { basketSegmentedOptions } from 'entities/Basket/Basket.const';
import { BasketWrapper } from 'entities/Basket/components/BasketWrapper';
import { QrfpList } from 'entities/Qrfp/components/QrfpList';

interface IComponentProps {}

export const BasketLayout: FC<IComponentProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  const onTabChange = (value: SegmentedValue) => {
    navigate(value as string);
  };

  return (
    <div className="basket-layout">
      <Segmented options={basketSegmentedOptions} value={location.pathname} onChange={onTabChange} />

      {pathname === ERoute.Basket ? <BasketWrapper role={EUserRole.User} /> : <QrfpList myQrfps />}
    </div>
  );
};
