import React from 'react';
import { Layout, Menu } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ESettingsMenuTab, EUserRole } from 'common/const/enum';
import { RootState } from 'app/store';
import { getSettingsNavItems } from 'entities/Settings/Settings.helper';
import { AccountSettings } from 'entities/Settings/components/AccountSettings';
import { SubdivisionsSettings } from 'entities/Settings/components/SubdivisionsSettings';
import { UsersSettings } from 'entities/Settings/components/UsersSettings';
import { IntegrationSetting } from 'entities/Settings/components/IntegrationSettings';
import { LegalEntityList } from 'entities/LegalEntity/components/LegalEntityList';
import { DiscountList } from 'entities/Discount/components/DiscountList';
import { Discount } from 'entities/Discount/components/Discount';
import { StockList } from 'entities/Stock/components/StockList';
import { ContractListForSellerManager } from 'entities/Contract/components/ContractListForSellerManager';
import { Contract } from 'entities/Contract/components/Contract';
import { DeliveryForContract } from 'entities/Delivery/components/DeliveryForContract';
import { AccountList } from 'entities/Account/components/AccountList';
import { ContractListForSuperAdmin } from 'entities/Contract/components/ContractListForSuperAdmin';

type AllType = ReturnType<typeof mapState>;

export const SettingsComponent: React.FC<AllType> = ({ authState }) => {
  const { data: auth } = authState;

  const { tab, id, buyerId } = useParams();

  const roles = auth?.access.roles;
  const isSeller = auth?.access.isSeller;
  const isSuperAdmin = roles?.includes(EUserRole.SuperAdmin);
  const items = getSettingsNavItems(roles, isSeller);

  const getSettingsContent = () => {
    switch (tab) {
      case ESettingsMenuTab.AccountList: {
        return <AccountList />;
      }
      case ESettingsMenuTab.ContractList: {
        if (isSuperAdmin) {
          return <ContractListForSuperAdmin />;
        } else {
          if (id && buyerId) {
            return <DeliveryForContract contractId={Number(id)} buyerId={Number(buyerId)} />;
          }

          if (id) {
            return <Contract id={Number(id)} />;
          }

          return <ContractListForSellerManager />;
        }
      }
      case ESettingsMenuTab.Account: {
        return <AccountSettings />;
      }
      case ESettingsMenuTab.Users: {
        return <UsersSettings />;
      }
      case ESettingsMenuTab.Subdivisions: {
        return <SubdivisionsSettings />;
      }
      case ESettingsMenuTab.Integration: {
        return <IntegrationSetting />;
      }
      case ESettingsMenuTab.LegalEntities: {
        return <LegalEntityList />;
      }
      case ESettingsMenuTab.Discounts: {
        return id ? <Discount id={Number(id)} /> : <DiscountList />;
      }
      case ESettingsMenuTab.Stocks: {
        return <StockList />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Layout className="settings">
      {/* @ts-ignore */}
      <Menu className="workplace-nav" items={items} selectedKeys={[tab]} />

      <div className="settings__content">{getSettingsContent()}</div>
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});

export const Settings = connect(mapState)(SettingsComponent);
