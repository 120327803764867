import { message } from 'antd';

export const showLoadingMessage = (duration?: number, content = 'Сохранение...') => {
  message.open({
    key: 'updatable',
    type: 'loading',
    content,
    duration,
  });
};

export const showSuccessMessage = () => {
  message.open({
    key: 'updatable',
    type: 'success',
    content: 'Данные сохранены',
  });
};

export const showErrorMessage = (error: string) => {
  message.open({
    key: 'updatable',
    type: 'error',
    content: error,
  });
};

export const destroyMessage = () => {
  message.destroy();
};
