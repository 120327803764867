import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Image, Input, Select, Switch, Upload } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useForm } from 'antd/es/form/Form';
import { debounce } from 'common/helpers/data.helper';
import { IFormValues } from 'common/models';
import { formValuesToPositions } from 'common/helpers/form.helper';
import { ALL_DOCS_EXTS, UPLOADING_DOCS_COUNT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ICatalog, ICatalogUpdatePayload } from 'entities/Catalog/Catalog.models';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { IProperty } from 'entities/Property/Property.models';
import { CategoryTemplateSearchModal } from 'entities/Modal/components/CategoryTemplateSearchModal';
import {
  findCategoryTemplateParent,
  findCategoryConditionalProperties,
  getSelectedPropertyCategory,
  getPropertyToDisplayIds,
  getGroupingPropertyOptions,
  getPropertiesForCategoryFilter,
  categoryPositionsToCategoryFilterFormInitialValues,
  getCategoryFilterDescription,
} from 'entities/Categories/Categories.helper';
import { CategoryFilterModal } from 'entities/Modal/components/CategoryFilterModal';
import { findSelectedTemplatePropertiesForDisplay } from 'entities/Catalog/Catalog.helper';

interface IComponentProps {
  catalog: ICatalog | null;
  category: ICategoryDetailed | null;
  loading: boolean;
  categoryTemplateTreeData: DataNode[];
  propertyList: IProperty[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CatalogFormComponent: FC<AllType> = (props) => {
  const {
    catalog,
    category,
    loading,
    categoryTemplateTreeData,
    propertyList,
    filesState,
    updateCatalog,
    updateCategory,
    uploadFiles,
  } = props;

  const { loading: uploadLoading } = filesState;

  const [openCategoryTemplateSearchModal, setOpenCategoryTemplateSearchModal] = useState<boolean>(false);
  const [openCategoryFilterModal, setOpenCategoryFilterModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [templateId, setTemplateId] = useState<number | undefined>();
  const [categoryConditionalProperties, setCategoryConditionalProperties] = useState<IProperty[]>([]);
  const [propertyToDisplayIds, setPropertyToDisplayIds] = useState<number[]>([]);
  const [groupingPropertyId, setGroupingPropertyId] = useState<number | undefined>();
  const [catalogForm] = useForm();
  const [categoryFilterForm] = useForm();

  const selectedTemplate = getSelectedPropertyCategory(category, propertyList);
  const groupingPropertyOptions = getGroupingPropertyOptions(categoryConditionalProperties);
  const propertiesForCategoryFilter = getPropertiesForCategoryFilter(selectedTemplate, propertyList);
  const categoryFilterDescription = getCategoryFilterDescription(category?.positions, propertyList);
  const categoryPositionsIds = category?.positions.map((position) => position.propertyId);

  const toggleOpenCategoryTemplateSearchModal = () => setOpenCategoryTemplateSearchModal((prev) => !prev);

  const toggleOpenCategoryFilterModal = () => setOpenCategoryFilterModal((prev) => !prev);

  const debouncedUpdateCatalog = debounce((payload: ICatalogUpdatePayload) => {
    updateCatalog(payload);
  });

  const onCatalogFormValuesChange = (value: IFormValues) => {
    const { name } = value;

    if (catalog?.id) {
      debouncedUpdateCatalog({ id: catalog.id, name });
    }
  };

  const onCategoryTemplateSearchModalCancelClick = () => {
    setSearchValue('');
    setTemplateId(selectedTemplate?.id);
    toggleOpenCategoryTemplateSearchModal();
  };

  const onCategoryTemplateSearchModalSelectClick = () => {
    if (templateId) {
      const parentTemplate = findCategoryTemplateParent(categoryTemplateTreeData, templateId);
      const selectedTemplate = parentTemplate?.children?.find((child) => child.key === templateId);

      if (category && parentTemplate && selectedTemplate) {
        const propertiesToDisplay = findSelectedTemplatePropertiesForDisplay(Number(selectedTemplate.key), propertyList);

        updateCategory({
          id: category.id,
          positions: [{ propertyId: Number(parentTemplate.key), values: [selectedTemplate.title as string] }],
          groupingPropertyId: null,
          propertiesToDisplay,
          onSuccess: onCategoryTemplateSearchModalCancelClick,
        });
      }
    }
  };

  const customRequest = async ({ file }: any) => {
    const response = await uploadFiles(file);

    if (category && response) {
      await updateCategory({ id: category.id, image: response.url });
    }
  };

  const onGroupingPropertyIdChange = (value: number) => {
    setGroupingPropertyId(value);

    if (category) {
      updateCategory({ id: category.id, groupingPropertyId: value });
    }
  };

  const changePropertyToDisplay = (propertyId: number, checked: boolean) => {
    const newPropertyToDisplayIds = checked
      ? [...propertyToDisplayIds, propertyId]
      : propertyToDisplayIds.filter((id) => id !== propertyId);

    setPropertyToDisplayIds(newPropertyToDisplayIds);

    if (category) {
      updateCategory({ id: category.id, propertiesToDisplay: newPropertyToDisplayIds });
    }
  };

  const onCategoryFilterModalCancelClick = () => {
    toggleOpenCategoryFilterModal();
  };

  const onCategoryFilterModalApplyClick = (values: IFormValues) => {
    const positions = formValuesToPositions(values);

    if (category) {
      updateCategory({ id: category.id, positions, onSuccess: onCategoryFilterModalCancelClick });
    }
  };

  useEffect(() => {
    catalogForm.setFieldsValue({ name: catalog?.name });
  }, []);

  useEffect(() => {
    const categoryConditionalProperties = findCategoryConditionalProperties(selectedTemplate, propertyList);
    const propertyToDisplayIds = getPropertyToDisplayIds(category?.propertiesToDisplay);
    const categoryFormInitialValue = categoryPositionsToCategoryFilterFormInitialValues(category?.positions);

    categoryFilterForm.setFieldsValue(categoryFormInitialValue);

    setTemplateId(selectedTemplate?.id);
    setCategoryConditionalProperties(categoryConditionalProperties);
    setPropertyToDisplayIds(propertyToDisplayIds);
    setGroupingPropertyId(category?.groupingProperty?.propertyId);
  }, [selectedTemplate]);

  if (!category) {
    return null;
  }

  return (
    <div className="catalog-form">
      <Form form={catalogForm} onValuesChange={onCatalogFormValuesChange}>
        <Form.Item name="name">
          <Input placeholder="Введите название" />
        </Form.Item>
      </Form>

      <div>
        <div className="catalog-form__form-item">
          <div className="catalog-form__form-item-label">
            <span className="catalog-form__form-item-title">Категория товара</span>

            <span className="catalog-form__form-item-description">{selectedTemplate?.displayName}</span>
          </div>

          <Button className="btn btn-default" onClick={toggleOpenCategoryTemplateSearchModal}>
            Изменить
          </Button>
        </div>

        {selectedTemplate && (
          <>
            <div className="catalog-form__form-item">
              <div className="catalog-form__form-item-label">
                <span className="catalog-form__form-item-title">Фильтры</span>

                <div className="catalog-form__form-item-description">{categoryFilterDescription}</div>
              </div>

              <Button className="btn btn-default" onClick={toggleOpenCategoryFilterModal}>
                Изменить
              </Button>
            </div>

            <div className="catalog-form__form-item">
              <span className="catalog-form__form-item-title">Изображение</span>

              <Upload accept={ALL_DOCS_EXTS} maxCount={UPLOADING_DOCS_COUNT} showUploadList={false} customRequest={customRequest}>
                <Button loading={uploadLoading}>Загрузить</Button>
              </Upload>
            </div>

            {category.image && (
              <div className="catalog-form__form-image">
                <Image src={category.image} preview={false} alt="Category image" />
              </div>
            )}

            <div className="catalog-form__form-subtitle">Листинг товаров в магазине</div>

            <div className="catalog-form__form-item">
              <span className="catalog-form__form-item-title">Группирующий параметр</span>

              <Select
                options={groupingPropertyOptions}
                value={groupingPropertyId}
                onChange={onGroupingPropertyIdChange}
                placeholder="Выбрать значение"
                suffixIcon={<ChevronIcon />}
                popupMatchSelectWidth={false}
                placement="bottomRight"
              />
            </div>

            {categoryConditionalProperties.map((property) => {
              const checked = propertyToDisplayIds.includes(property.id);

              return (
                <div key={property.id} className="catalog-form__form-item">
                  <span className="catalog-form__form-item-title">{property.displayName}</span>

                  <div className="catalog-form__form-item-container">
                    <span>Виден в магазине</span>

                    <Switch checked={checked} onChange={(value) => changePropertyToDisplay(property.id, value)} />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      <CategoryTemplateSearchModal
        open={openCategoryTemplateSearchModal}
        loading={loading}
        treeData={categoryTemplateTreeData}
        searchValue={searchValue}
        templateId={templateId}
        changeSearchValue={setSearchValue}
        changeTemplateId={setTemplateId}
        onCancel={onCategoryTemplateSearchModalCancelClick}
        onSelect={onCategoryTemplateSearchModalSelectClick}
      />

      <CategoryFilterModal
        open={openCategoryFilterModal}
        form={categoryFilterForm}
        loading={loading}
        propertyList={propertiesForCategoryFilter}
        template={selectedTemplate}
        defaultActiveKeys={categoryPositionsIds}
        onCancel={onCategoryFilterModalCancelClick}
        onApply={onCategoryFilterModalApplyClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  filesState: state.filesState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateCatalog: dispatch.catalogState.updateCatalog,
  updateCategory: dispatch.categoryState.updateCategory,
  uploadFiles: dispatch.filesState.uploadFiles,
});

export const CatalogForm = connect(mapState, mapDispatch)(CatalogFormComponent);
