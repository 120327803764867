import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Avatar } from 'common/components/Avatar';
import { ESearchParams } from 'common/const/enum';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IUser } from 'entities/Users/Users.models';
import { IAddress } from 'entities/Addresses/Addresses.models';
import { IWorkspace } from 'entities/Workspaces/Workspaces.models';
import { getUserName } from 'entities/Users/Users.helper';
import { ISubdivision, ISubdivisionListState } from 'entities/Subdivisions/Subdivisions.models';

export const renderSubdivisionUserCollectionRecords = (setSearchParams: (key: ESearchParams, value: string | number) => void) => {
  return [
    {
      key: 'avatar',
      render: (record) => <Avatar size={32} firstName={record.firstName} lastName={record.lastName} />,
      width: '44px',
    },
    {
      key: 'info',
      render: (record) => {
        return (
          <div>
            <Button className="btn-link" type="link" onClick={() => setSearchParams(ESearchParams.UserId, record.id)}>
              {getUserName(record)}
            </Button>

            <div className="subdivision-users__table_item-user-email">{record.email}</div>
          </div>
        );
      },
    },
  ] as ColumnsType<IUser>;
};

export const renderSubdivisionAddressRecords = (
  updateAddressAssignment: (assressid: number) => void,
  handleDeleteClick: (assressid: number) => void,
) => {
  return [
    {
      key: 'icon',
      render: (record) => <div className={`subdivision-addresses__table_item-icon ${record.isMain && 'isMain'}`} />,
      width: '32px',
    },
    {
      key: 'postalCode',
      render: (record) => <span>{record.postalCode}</span>,
      width: '60px',
    },
    {
      key: 'name',
      render: (record) => <span>{record.name}</span>,
    },
    {
      key: 'tag',
      render: (record) => (record.isMain ? <div className="subdivision-addresses__table_item-tag">Основной</div> : null),
      width: '90px',
    },
    {
      key: 'actions',
      render: (record) => (
        <ThreeDotMenu
          items={[
            {
              key: 'assignment',
              label: (
                <Button onClick={() => updateAddressAssignment(record.id)} disabled={record.isMain}>
                  Сделать основным
                </Button>
              ),
              className: 'edit',
            },
            {
              key: 'delete',
              label: <Button onClick={() => handleDeleteClick(record.id)}>Удалить</Button>,
              className: 'delete',
            },
          ]}
        />
      ),
      width: '32px',
    },
  ] as ColumnsType<IAddress>;
};

export const renderSubdivisionWorkspacesRecords = (
  handleEditWorkspaceClick: (workspaceId: number) => void,
  handleCopyWorkspaceClick: (workspace: IWorkspace) => void,
  handleDeleteWorkspaceClick: (workspace: IWorkspace) => void,
) => {
  return [
    {
      key: 'icon',
      render: () => <div className="subdivision-workspaces__table_item-icon" />,
      width: '32px',
    },
    {
      key: 'name',
      render: (record) => <span>{record.name}</span>,
    },
    {
      key: 'actions',
      render: (record) => (
        <ThreeDotMenu
          items={[
            {
              key: 'edit',
              label: <Button onClick={() => handleEditWorkspaceClick(record.id)}>Редактировать</Button>,
              className: 'edit',
            },
            {
              key: 'copy',
              label: <Button onClick={() => handleCopyWorkspaceClick(record)}>Дублировать</Button>,
              className: 'edit',
            },
            {
              key: 'delete',
              label: <Button onClick={() => handleDeleteWorkspaceClick(record)}>Удалить</Button>,
              className: 'delete',
            },
          ]}
        />
      ),
      width: '32px',
    },
  ] as ColumnsType<IWorkspace>;
};

export const updateSubdivisionListState = {
  addSubdivision: (state: ISubdivisionListState, payload: ISubdivision) => ({ ...state, data: [...state.data, payload] }),
  updateSubdivision: (state: ISubdivisionListState, payload: ISubdivision) => ({
    ...state,
    data: state.data.map((subdivision) => (subdivision.id === payload.id ? payload : subdivision)),
  }),
  deleteSubdivision: (state: ISubdivisionListState, payload: number) => ({
    ...state,
    data: state.data.filter((subdivision) => subdivision.id !== payload),
  }),
};
