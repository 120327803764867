import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Layout, Spin, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EMessage, ERequestStatus } from 'common/const/enum';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { renderRequestListRecords, requestListToDataSource } from 'entities/Requests/Requests.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const RequestListComponent: React.FC<AllType> = (props) => {
  const { authState, userState, requestListState, specificationState, getUserById, getRequestList, createSpecification } = props;

  const { data: auth } = authState;
  const { data: user } = userState;
  const { data: requestList } = requestListState;
  const { loading: specificationLoading } = specificationState;

  const [selectedRequestIds, setSelectedRequestIds] = useState<number[]>([]);
  const navigate = useNavigate();

  const userId = auth?.access.userId;
  const subdivisionId = user?.subdivisionId;
  const dataSource = requestListToDataSource(requestList);
  const columns = renderRequestListRecords(onRequestClick);

  function onRequestClick(id: number) {
    navigate(`/request-list/${id}`);
  }

  const onGenerateSpecificationClick = async () => {
    const specification = await createSpecification({ requestIds: selectedRequestIds });

    if (specification) {
      navigate(`/specification/${specification.id}`);
    }
  };

  const { contentLoading } = useContentLoader(async () => {
    if (userId) {
      await getUserById(userId);
      await getRequestList({ status: ERequestStatus.Active, limit: 0 });
    }
  });

  return (
    <Layout className="request-list">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <Spin wrapperClassName="request-list__spin" spinning={specificationLoading}>
          <div className="request-list__container">
            <div className="request-list__title">Заявки пользователей</div>

            {!subdivisionId ? (
              <div>{EMessage.AccountIsNotLinkedToSubdivision}</div>
            ) : (
              <>
                <Button className="btn btn-primary" onClick={onGenerateSpecificationClick} disabled={!selectedRequestIds.length}>
                  Сформировать Спецификацию
                </Button>

                <Table
                  rowSelection={{
                    selectedRowKeys: selectedRequestIds,
                    onChange: (selectedRowKeys: React.Key[]) => {
                      setSelectedRequestIds(selectedRowKeys as number[]);
                    },
                  }}
                  dataSource={dataSource}
                  columns={columns}
                  className="request-list__table"
                  pagination={false}
                  showHeader={false}
                />
              </>
            )}
          </div>
        </Spin>
      )}
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  userState: state.userState,
  requestListState: state.requestListState,
  specificationState: state.specificationState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserById: dispatch.userState.getUserById,
  getRequestList: dispatch.requestListState.getRequestList,
  createSpecification: dispatch.specificationState.createSpecification,
});

export const RequestList = connect(mapState, mapDispatch)(RequestListComponent);
