import React from 'react';
import { Layout, Segmented } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { SegmentedValue } from 'antd/es/segmented';
import { ERoute } from 'common/const/enum';
import { buyersWorkplaceSegmentedOptions } from 'entities/BuyersWorkplace/BuyersWorkplace.const';
import { RequestList } from 'entities/Requests/components/RequestList';
import { SpecificationList } from 'entities/Specifications/components/SpecificationList';

export const BuyersWorkplace: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  const onTabChange = (value: SegmentedValue) => {
    navigate(value as string);
  };

  return (
    <Layout className="buyers-workplace">
      <Segmented options={buyersWorkplaceSegmentedOptions} value={pathname} onChange={onTabChange} />

      {pathname === ERoute.RequestList ? <RequestList /> : <SpecificationList />}
    </Layout>
  );
};
