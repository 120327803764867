import axios from 'axios';
import {
  IAccountCollectionDto,
  IAccountListParams,
  IAccount,
  IAccountBlockPayload,
  IAccountCreatePayload,
  IAccountDeletePayload,
  IAccountUnblockPayload,
  IAccountUpdatePayload,
} from 'entities/Account/Account.models';

const basePath = 'account';

export const accountTransport = {
  getAccountList: (params: IAccountListParams): Promise<IAccountCollectionDto> => axios.get(`${basePath}`, { params }),
  getAccountById: (id: number): Promise<IAccount> => axios.get(`${basePath}/${id}`),
  createAccount: (payload: IAccountCreatePayload): Promise<IAccount> => axios.post(`${basePath}`, payload),
  updateAccount: (payload: IAccountUpdatePayload): Promise<IAccount> => axios.patch(`${basePath}`, payload),
  blockAccount: (payload: IAccountBlockPayload): Promise<IAccount> => axios.patch(`${basePath}/block`, payload),
  unblockAccount: (payload: IAccountUnblockPayload): Promise<IAccount> => axios.patch(`${basePath}/unblock`, payload),
  deleteAccount: (payload: IAccountDeletePayload): Promise<IAccount> => axios.delete(`${basePath}`, { data: payload }),
};
