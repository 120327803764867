import React, { useEffect } from 'react';
import { Layout, Select, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { filterSelectOption } from 'common/helpers/filterOption.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as UpDownIcon } from 'app/assets/images/up-down.svg';
import {
  activeBasketsListDataToPayload,
  getActiveBasketsListFilterOptions,
  renderActiveBasketsListRecords,
} from 'entities/SellerManagerWorkplace/SellerManagerWorkplace.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ActiveBasketsListComponent: React.FC<AllType> = ({ basketListState, getBasketList }) => {
  const { data: basketList, loading: basketListLoading } = basketListState;

  const navigate = useNavigate();

  const dataSource = activeBasketsListDataToPayload(basketList);
  const columns = renderActiveBasketsListRecords();
  const { basketsIdsOptions, basketsUsersOptions } = getActiveBasketsListFilterOptions(basketList);

  const onBasketSelect = (value: number) => {
    navigate(`/active-baskets-list/${value}`);
  };

  useEffect(() => {
    getBasketList({ limit: 0 });
  }, []);

  return (
    <Layout className="active-baskets-list">
      <Spin wrapperClassName="active-baskets-list__spin" spinning={basketListLoading}>
        <div className="active-baskets-list__container">
          <div className="active-baskets-list__filter">
            <div>
              <div className="active-baskets-list__filter-item-title">№ корзины</div>

              <Select
                className="active-baskets-list__filter-item-select"
                options={basketsIdsOptions}
                onChange={onBasketSelect}
                suffixIcon={<UpDownIcon />}
                filterOption={filterSelectOption}
                optionFilterProp="children"
                showSearch
              />
            </div>

            <div>
              <div className="active-baskets-list__filter-item-title">Имя пользователя</div>

              <Select
                className="active-baskets-list__filter-item-select"
                options={basketsUsersOptions}
                onChange={onBasketSelect}
                suffixIcon={<UpDownIcon />}
                filterOption={filterSelectOption}
                optionFilterProp="children"
                showSearch
              />
            </div>
          </div>

          <div className="active-baskets-list__content">
            <div className="active-baskets-list__title">Список активных корзин</div>

            <Table
              className="active-baskets-list__table"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              showHeader={false}
              size="small"
              onRow={(record) => ({ onClick: () => onBasketSelect(record.id) })}
            />
          </div>
        </div>
      </Spin>
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  basketListState: state.basketListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasketList: dispatch.basketListState.getBasketList,
});

export const ActiveBasketsList = connect(mapState, mapDispatch)(ActiveBasketsListComponent);
