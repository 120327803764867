import { ERoute } from 'common/const/enum';

export const basketSegmentedOptions = [
  {
    label: 'Корзина',
    value: ERoute.Basket,
  },
  {
    label: 'Запросы',
    value: ERoute.QrfpList,
    // TODO remove temp disabled
    disabled: true,
  },
];
