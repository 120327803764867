import axios from 'axios';
import {
  ISpecificationCollectionDto,
  ISpecificationListParams,
  ISpecification,
  ISpecificationCreateOrderPayload,
  ISpecificationCreatePayload,
  ISpecificationUpdatePayload,
} from 'entities/Specifications/Specifications.models';

const basePath = 'specification';

export const specificationsTransport = {
  getSpecificationList: (params: ISpecificationListParams): Promise<ISpecificationCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getSpecificationById: (id: number): Promise<ISpecification> => axios.get(`${basePath}/${id}`),
  createSpecification: (payload: ISpecificationCreatePayload): Promise<ISpecification> => axios.post(`${basePath}`, payload),
  updateSpecification: (payload: ISpecificationUpdatePayload): Promise<ISpecification> => axios.patch(`${basePath}`, payload),
  createSpecificationOrder: (payload: ISpecificationCreateOrderPayload): Promise<ISpecification> =>
    axios.post(`${basePath}/order`, payload),
  uploadSpecificationExcel: (id: number): Promise<ArrayBuffer> =>
    axios.get(`${basePath}/file/${id}`, { responseType: 'arraybuffer' }),
};
